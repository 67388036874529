<div>
  <div class="title_wrap w-100 sticky-top">
    <div class="d-flex mx-2 mx-md-3 justify-content-between align-items-center items-wrapper">
      <div class="position-relative w-10 my-2">
        <div class="text-white nounderline  d-md-block" (click)="goToWikiMasterSite()">
          <div class="py-2 text-center">
            <img class="w-90" src="./assets/img/w.png" alt="W" />
            <small class="m-0 mt-2 d-none d-md-block">WikiMaster</small>
          </div>
        </div>
      </div>

      <div class="position-relative">
        <div class="text-white nounderline  d-md-block">
          <div class="py-2 text-center">
            <div class="qs-t">{{ timer }}</div>
            <small class="m-0 mt-2 d-none d-md-block">Time</small>
          </div>
        </div>
      </div>

      <div class="position-relative">
        <div class="text-white nounderline  d-md-block">
          <div class="py-2 text-center">
            <h3 class="text-white m-0">{{ tag_name }} Quiz</h3>
          </div>
        </div>
      </div>

      <div class="position-relative">
        <div class="text-white nounderline d-md-block">
          <div class="py-2 text-center">
            <h4 class="text-white m-0">
              {{ index_q + 1 }}/{{ result && result.questions.length }}
            </h4>
          </div>
        </div>
      </div>

      <div class="position-relative w-10">
        <div class="text-white nounderline d-md-block" (click)="onExit()">
          <div class="py-2 text-center">
            <div class="qs-x">
              <img class="w-100" src="./assets/img/x-btn.png" alt="W" />
            </div>
            <small class="m-0 mt-2 d-none d-md-block">Exit</small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-8 col-12 mt-5 mx-auto">
    <div class="text-center">
      <img class="border-0 text-center quiz-pic" *ngIf="loading" src="./assets/img/loading1.gif" />
      <img class="text-center quiz-pic border" *ngIf="!loading" src="{{
          result && result.questions && result.questions[index_q].img_url
        }}"
        onError="this.src='./assets/img/wikimaster_logo.png'" />
    </div>
    <h4 class="blue mx-md-3 mt-3 text-center mb-0">
      {{ result && result.questions[index_q].text }}
    </h4>
  </div>

  <ul #alts class="mx-auto justify-content-center list-unstyled row">
    <ng-container *ngIf="result">
      <li class="alternative-class mx-3 mx-md-0 col-sm-12 col-lg-7 px-3 px-md-5 mt-2 mt-md-4 blue_bg text-center text-white rounded p-2 p-md-3"
        *ngFor="
          let item of result.questions[index_q].alternatives" (click)="onSelectAnswer(item)"
        [id]="item.id">
        {{ item.text }}
      </li>
    </ng-container>
  </ul>
</div>
<ng-template #abort>
    <div class="text-center popup-content">
        <p class="blue">
          Do you want to abort the quiz or reprt question ?
        </p>
        <div class="d-flex justify-content-around">
          <button class="border-0 blue_bg white rounded w-25 p-1 pointer" (click)="onCancel()">Cancel</button>
          <button class="border-0 green_bg white rounded w-25 p-1 pointer" (click)="onReportQuiz()">Report</button>
          <button class="border-0 grey_bg white rounded w-25 p-1 pointer" (click)="onAbortQuiz()">Abort</button>
        </div>
      </div>
</ng-template>
