<div class="center-abs">
    <form class="mx-auto mt-4" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <input type="text" formControlName="firstName" class="form-control signup" placeholder="First Name"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div class="yellow" *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="lastName" class="form-control signup" placeholder="Last Name"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div class="yellow" *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <input type="email" formControlName="email" class="form-control signup" placeholder="Email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div class="yellow" *ngIf="f.email.errors.required">Email is required</div>
                <div class="yellow" *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" formControlName="password" class="form-control signup" placeholder="Password"
             [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div class="yellow" *ngIf="f.password.errors.required">Password is required</div>
                <div class="yellow" *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-group">
            <input type="password" formControlName="confirmPassword" class="form-control signup" placeholder="Re-Password"
             [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div class="yellow" *ngIf="f.confirmPassword.errors.required">Re-enter Password is required</div>
                <div class="yellow" *ngIf="f.confirmPassword.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input mt-1" type="checkbox" value="" id="termsCheck">
            <label class="form-check-label blue" for="termsCheck">
                By proceeding, you also agree to WOKcraft <span (click)="SiteUtils.goToTermsOfUseSite()" class="underline pointer">Terms of Use</span> and
                <span (click)="SiteUtils.goToPrivacyPolicySite()" class="underline pointer">Privacy Policy</span>.
            </label>
          </div>
        <div class="form-group">
            <button type="submit" class="pointer w-50 rounded white green_bg mx-auto d-table my-4">
                <span class="d-table-cell align-middle pl-2">
                    <img class="w-100" src="./assets/img/w-white.png" />
                  </span>
                  <h5 class="text-center font-weight-normal d-table-cell align-middle p-1">Sign up</h5>
            </button>
        </div>
    </form>





</div>
