<div class="container center-abs">

  <div class="rows-wrapper">

    <ul class="list-group list-unstyled m-0">
      <li *ngFor="let quiz of quizzes" class="lq_row clearfix mb-0">


        <div class="rq_row my-3 clearfix">
          <div class="w-25 pr-1 float-left h-100 d-flex align-items-center justify-content-center">
            <img src="{{icon}}" onError="this.src='./assets/img/wikimaster_logo.png'">
          </div>
          <div class="w-75 d-flex align-items-start flex-column h-100 float-right">
            <div class="mb-auto w-100 clearfix">
              <h6 class="w-80 blue float-left font-weight-normal">{{quiz.name}}</h6>
              <h6 class="w-20 blue text-right float-right font-weight-normal">{{quiz.percent ? quiz.percent.toFixed() : 0}}%</h6>
            </div>
            <div class="w-100 d-flex justify-content-between">
              <div></div>
              <div></div>
              <div></div>
              <div>
                <img class="w-75 pointer" src="./assets/img/view-blue.png" (click)="onReview(quiz)" />
                <!-- <img *ngIf="!quiz.chs" class="w-75" src="./assets/img/view-blue.png" (click)="onReview(quiz)" /> -->
                <!-- <img *ngIf="quiz.chs" class="w-75" src="./assets/img/view-green.png" /> -->
              </div>
              <div class="align-self-center green col-3 text-right p-0">{{quiz.wokbits}} Wb</div>
            </div>
          </div>
        </div>
        <div class="w-100 hr"></div>
      </li>
    </ul>
    <div *ngIf="loading || apiManager.canLoadMore" class="text-center col-md-8 col-12 my-3 mx-auto">
      <img *ngIf="loading" class="border-0 w-25" src="./assets/img/loading1.gif" />
      <button *ngIf="!loading" class="blue pointer rounded blue_bg white" (click)="loadNext()">Show More </button>
    </div>
