import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../auth/authentication.service';
import { APIManager } from '../../conn';
import { User, WMApi, UserMapper, Utils, SiteUtils } from '../../core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signup-form',
    templateUrl: './signup-form.component.html',
    styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {
    signupForm: FormGroup;
    submitted = false;
    SiteUtils = SiteUtils;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        private loginService: AuthenticationService) { }

    ngOnInit() {
        this.signupForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(3)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(3)]],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.signupForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.signupForm.invalid) {
            return;
        }
        // Body Param:
        // password=bla&os=android
        // &lngid=901
        // &continent_id=8
        // &appv=v3.37.a2
        // &last_name=Lela
        // &first_name=Khaled&email=yz@kl.com&platform=WW&country_id=254
        const apiManager = new APIManager<User>(WMApi.USER_SIGNUP, UserMapper.getInstance());
        const email = this.signupForm.controls['email'].value;
        const password = this.signupForm.controls['password'].value;
        const loginHash = Utils.md5LoginHash(email, password);
        apiManager.setQueryString('first_name', this.signupForm.controls['firstName'].value);
        apiManager.setQueryString('last_name', this.signupForm.controls['lastName'].value);
        apiManager.setQueryString('email', email);
        apiManager.setQueryString('password', password);
        apiManager.setQueryString('lngid', '901');

        this.loginService.login(apiManager, loginHash).subscribe(() => {
            this.router.navigate([], { fragment: null });
            location.reload();
        });
    }

}
