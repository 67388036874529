import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItems } from '../core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rq',
  templateUrl: './rq.component.html',
  styleUrls: ['./rq.component.css']
})
export class RqComponent implements OnInit, OnDestroy {
  fragment: string;
  // Store a reference to the enum
  menuItem = MenuItems;
  subscription: Subscription;
  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscription = this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
