import { Mapper } from '../../conn';
import { Quiz } from '../model/quiz';
import { QuestionMapper } from './question.mapper';
// {"qid":128139729,"qtext":"What country is 'ITWorx' from?","img_desc":null,"img_url":null,
// "uid":106292437,"category_id":102,"answer_time":null,
// "alternatives":[{"aid":128141497,"text":"Libya","status":0},...]}
export class QuizMapper implements Mapper<Quiz> {

    public static getInstance(): QuizMapper {
        return new QuizMapper();
    }

    fromJson(json: any): Quiz {
        const qz = new Quiz();
        qz.id = json.qzid;
        qz.wokbits = json.wokbits;
        qz.name = json.hasOwnProperty('name') ? json.name : json.qzn;
        // qz.name = json.qzn;
        qz.percent = json.cp;
        qz.date = json.date;
        qz.chs = json.chs;
        qz.user_percentage = json.user_percentage;
        qz.correct_answers = json.correct_answers;
        qz.incorrect_answers = json.incorrect_answers;
        qz.total_questions = json.total_questions;
        qz.total_time = json.total_time;
        qz.tid = json.tid;
        qz.position_in_tag = json.position_in_tag;
        qz.tag_total_taken = json.tag_total_taken;
        qz.quiz_percentage = json.quiz_percentage;
        qz.tag_percentage = json.tag_percentage;
        // Convert json array of alts array of objs.
        if (json.hasOwnProperty('qs')) {
        const qMapper = new QuestionMapper();
        const qs = json.qs.map((q: any) => qMapper.fromJson(q));
        qz.questions = qs;
      }
        return qz;
    }

    toJson(qz: Quiz) {
        const qMapper = new QuestionMapper();
        return {
            qzid: qz.id,
            // Convert alt array to json array
            questions: qz.questions.map(q => qMapper.toJson(q))
        };
    }
}
