import { Component, OnInit } from '@angular/core';
import { WOKConnection, APIManager, ItemResponse, ListResponse, ConfigService } from '../conn';
import { ActivatedRoute, Router } from '@angular/router';
import { WMApi, User, UserMapper, UserManager } from '../core';
import { config } from '../../environments/config';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-total-qs',
  templateUrl: './total-qs.component.html',
  styleUrls: ['./total-qs.component.css']
})
export class TotalQsComponent implements OnInit {

  icon: any;
  woker: Observable<User>;
  tid: number;
  tag_name: string;
  qzid: any;
  users: User[];
  loading: boolean;
  apiManager: APIManager<User>;
  private subscription: Subscription;
  private userMapper: UserMapper;
  Math: any;
  constructor(
    private service: WOKConnection,
    route: ActivatedRoute,
    userManager: UserManager,
    configService: ConfigService) {
    // this.apiManager = new APIManager<User>(WMApi.WWTQ,  UserMapper.getInstance());
    this.tid = route.snapshot.queryParams['tid'];
    this.woker = userManager.getUser();
    this.Math = Math;
    this.icon = configService.getConfig()[config.TAG_ICON];
    this.subscription = new Subscription();
    this.userMapper = UserMapper.getInstance();
  }

  ngOnInit() {
    this.tag_name = localStorage.getItem(config.wikiNameParamKey);
    this.listUsers(new APIManager<User>(WMApi.WWTQ, this.userMapper));
  }

  listUsers(apiManager: APIManager<User>) {
    this.loading = true;
    this.apiManager = apiManager;
    this.apiManager.setPathParams(this.tid);
    this.subscription.add(this.service.execute(this.apiManager).subscribe((response: ListResponse<User>) => {
      if (response && response.data) {
        this.users = this.users && this.apiManager.append
          ? this.users.concat(response.data)
          : response.data;
      } else {
        this.users = response.data;
      }
      this.loading = false;
    }));
  }

  loadNext() {
    this.listUsers(this.apiManager);
  }

}
