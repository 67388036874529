import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ConfigService } from '../conn';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css'],
})
export class ModalsComponent implements AfterViewInit {
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('hide') hide: TemplateRef<any>;

  modalRef: BsModalRef;
  alerBody: any;
  textBody: boolean;
  // Pleas note when inject service in constractor then you have to add in module provider
  constructor(private modalService: BsModalService,
    private configService: ConfigService) { }

  ngAfterViewInit() {
    this.configService.getAlert().subscribe(body => {
      if (typeof body !== 'boolean') {
        this.alerBody = body;
        this.textBody = typeof body === 'string';
        this.modalRef = this.modalService.show(this.template);
      } else if (!body && this.modalRef) {
        this.modalRef.hide();
      }
    });
  }
}
