import { Mapper } from '../../conn';
import { User } from '../model/user';
// {"tid":129659943,"user_name":"Egypt","tqt":813}
export class UserMapper implements Mapper<User> {

    public static getInstance(): UserMapper {
        return new UserMapper();
    }

    fromJson(json: any): User {
        const user = new User();
        // When user info assign to user obj or direct on data
        const userObj = json.hasOwnProperty('user') ? json.user : json;

        user.id = userObj.uid;
        user.firstName = userObj.fn;
        user.lastName = userObj.ln;
        user.email = userObj.email;
        user.level = userObj.level;
        user.password = userObj.password;
        user.alias = userObj.alias;
        user.loginHash = userObj.email_hash;
        user.wokbits = userObj.wokbits;
        user.avatar = userObj.avatar;
        user.lngid = userObj.lngid;
        user.answerId = json.aid;
        user.time = json.time;
        user.status = json.status;
        user.cp = json.cp;
        user.countryID = json.country_id;

        return user;
    }

    toJson(user: User) {
        return {
            uid: user.id,
            fn: user.firstName,
            ln: user.lastName,
            email: user.email,
            password: user.password,
            alias: user.alias,
            email_hash: user.loginHash,
            wokbits: user.wokbits,
            avatar: user.avatar,
            lngid: user.lngid
        };
    }
}
