<div
  [ngTemplateOutlet]="footer"
  [ngTemplateOutletContext]="{ shortFooter: shortFooter }"
></div>

<ng-template #footer let-shortFooter="shortFooter">
  <div class="footer p-1 text-center">
    <span
      *ngIf="shortFooter"
      class="d-inline pointer underline"
      (click)="openFooter()"
    >WikiMaster</span>
    <span
      ><span *ngIf="!shortFooter">WikiMaster</span> is not an affiliate of
    </span>
    <span
      >Wikipedia</span
    >
    <span
      *ngIf="shortFooter; else footerMore"
      class="d-inline pointer"
      (click)="openFooter()"
    >
      &nbsp; ...
    </span>
  </div>

  <ng-template #footerMore>
    <span class="d-md-inline">
      <span>
        or
        <span class="underline pointer" (click)="SiteUtils.goToWikimediaSite()"
          >Wikimedia Foundation</span
        >
        or any of its subsidiaries.
      </span>

      <span class="d-block"
        >WikiMaster is a trademark of
        <span class="underline pointer" (click)="SiteUtils.goToWOKcraftSite()"
          >WOKcraft Inc</span
        >
        and host a service of multiple choice questions in order to learn more
        on Wikipedia.</span
      >
      <span class="d-block"
        >The web version is a limited version of the mobile app in
        <span class="underline pointer" (click)="SiteUtils.goToGP()"
          >Google Play</span
        >
        and
        <span class="underline pointer" (click)="SiteUtils.goToAppStore()"
          >AppStore</span
        >. Download
        <span class="underline pointer" (click)="SiteUtils.goToWikiMasterSite()"
          >WikiMaster</span
        ></span
      >
    </span>
  </ng-template>
</ng-template>
