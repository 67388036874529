import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UrlSerializer } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.router';
import { AuthenticationModule } from './auth/authentication.module';
import { ChatComponent } from './chat/chat.component';
import { MessagesComponent } from './chat/messages/messages.component';
import { CustomUrlSerializer } from './core';
import { QuestionModule } from './cq/question.module';
import { EqComponent } from './eq/eq.component';
import { FooterComponent } from './footer/footer.component';
import { LoginModule } from './login/login.module';
import { LqComponent } from './lq/lq.component';
import { ModalsComponent } from './modals/modals.component';
import { RQModule } from './rq/rq.module';
import { TakeQuizComponent } from './take-quiz/take-quiz.component';
import { WikiComponent } from './wiki/wiki.component';
import { TotalQsComponent } from './total-qs/total-qs.component';
import { EotComponent } from './eot/eot.component';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    TakeQuizComponent,
    WikiComponent,
    LqComponent,
    ChatComponent,
    ModalsComponent,
    MessagesComponent,
    EqComponent,
    FooterComponent,
    TotalQsComponent,
    EotComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AuthenticationModule,
    QuestionModule,
    LoginModule,
    ModalModule.forRoot(), // modals alert msg
    RQModule],
  providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }], // handle wikipedia names contains -,()
  bootstrap: [AppComponent]
})
export class AppModule { }
