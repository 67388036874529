import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { config } from '../../environments/config';
import {
  APIManager,
  ItemResponse,
  WOKConnection,
  ConfigService,
} from '../conn';
import { Alternative, Answer, Quiz, QuizMapper, Utils, WMApi } from '../core';

@Component({
  selector: 'app-take-quiz',
  templateUrl: './take-quiz.component.html',
  styleUrls: ['./take-quiz.component.css'],
})
export class TakeQuizComponent implements OnInit, OnDestroy {
  @ViewChild('alts') altsElementRef: ElementRef; // question alternatives ul contains alts li.
  @ViewChild('abort') abort: TemplateRef<any>;
  tid: number;
  timer: number;
  private timerSubscription: Subscription;
  private subscription: Subscription;
  public tag_name: string; // wikipedia article name
  public language: string;
  service_url_quiz: string;
  service_url_tag_info: string;
  public result: Quiz;
  public loading: boolean;
  public index_q: number;
  private timer_start: number;
  private css_alternative_correct = 'alternative-correct';
  private css_alternative_wrong = 'alternative-wrong';
  private delay_time_after_submit = 1200;
  private default_limit = 10;
  private fetchQuizAPIManager: APIManager<Quiz>;
  private submitAPIManager: APIManager<Answer>;
  private time: Date;
  private start_clock: number;
  private lastSubmittedIndex: number;
  private submissiontoken: string;
  private correctSound: HTMLAudioElement;
  private incorrectSound: HTMLAudioElement;

  constructor(
    private service: WOKConnection,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private configService: ConfigService
  ) {
    this.index_q = 0;
    this.timer_start = config.answerTime;
    this.fetchQuizAPIManager = new APIManager(
      WMApi.START_QUIZ,
      QuizMapper.getInstance()
    );
    this.submitAPIManager = new APIManager(WMApi.QUIZ_ANSWER_SUBMIT, null);
    this.subscription = new Subscription();
  }

  private start_count_down(): void {
    this.time = new Date();
    this.start_clock = this.time.getTime();
    this.timerSubscription = interval(1000).subscribe((x) => this.countdown());
    this.subscription.add(this.timerSubscription);
  }
  private countdown() {
    if (this.timer === 0) {
      this.timerSubscription.unsubscribe();
      this.onSelectAnswer(null);
    } else if (this.timer >= 1) {
      this.timer -= 1;
    }
  }
  goToWikiMasterSite(): void {
    window.open('https://www.wikimaster.com', '_blank');
  }

  private calculate_answer_time(): number {
    this.time = new Date();
    return (this.time.getTime() - this.start_clock) / 1000;
  }

  private restart_timer(): void {
    this.timer = this.timer_start;
    this.start_count_down();
  }

  onSelectAnswer(selected: Alternative) {
    if (this.lastSubmittedIndex >= this.index_q) {
      return;
    }
    this.lastSubmittedIndex = this.index_q;
    const answer = new Answer();
    this.timerSubscription.unsubscribe();
    const question = this.result.questions[this.index_q];
    this.focusAnswer(selected, question.alternatives);
    if (selected) {
      answer.id = selected.id;
      answer.time = this.calculate_answer_time();
      this.playAudio(selected.status);
    } else {
      answer.id = null;
      answer.time = this.timer_start;
    }

    this.submitAnswer(question.id, answer); // submit question answer.

    /**
     * Check next action.
     */
    setTimeout(() => {
      if (this.index_q + 1 < this.result.questions.length) {
        this.index_q += 1;
        this.restart_timer();
      } else {
        this.onFinished();
      }
    }, this.delay_time_after_submit);
  }

  onFinished() {
    this.router.navigate(
      [
        this.language,
        Utils.wikipediaURIFormat(this.tag_name),
        'quiz',
        'finish',
      ],
      {
        queryParams: { tid: this.tid, qzid: this.result.id },
      }
    );
  }

  onExit() {
    this.configService.setAlert(this.abort);
  }
  onAbortQuiz() {
    this.router.navigate([
      this.language,
      Utils.wikipediaURIFormat(this.tag_name),
    ]);
    this.onCancel();
  }
  onCancel() {
    this.configService.setAlert(false);
  }
  onReportQuiz() {
    this.onCancel();
  }

  focusAnswer(selected: Alternative, alts: Alternative[]) {
    const items = this.altsElementRef.nativeElement.getElementsByTagName('li');
    const correctIndex = alts.findIndex((alt) => alt.status);
    items[correctIndex].classList.add(this.css_alternative_correct);
    if (selected && !selected.status) {
      const wrongIndex = alts.findIndex((alt) => alt.id === selected.id);
      items[wrongIndex].classList.add(this.css_alternative_wrong);
    }
  }

  submitAnswer(qid: number, answer: Answer): void {
    // qzid=155959745&time=12.695&aid=148559959&qid=148559887&tid=129592770&status=1
    // this.submitAPIManager.setQueryString('qzid', this.result.id);
    this.submitAPIManager.setQueryString('qid', qid);
    this.submitAPIManager.setQueryString('aid', answer.id);
    this.submitAPIManager.setQueryString('time', answer.time);
    this.submitAPIManager.setQueryString(
      'submissiontoken',
      this.submissiontoken
    );
    this.subscription.add(
      this.service.execute(this.submitAPIManager).subscribe()
    );
  }

  playAudio(status: boolean) {
    if (status) {
      this.correctSound.play();
    } else {
      this.incorrectSound.play();
    }
  }

  ngOnInit() {
    this.tag_name = localStorage.getItem(config.wikiNameParamKey);
    this.language = localStorage.getItem(config.language);

    this.titleService.setTitle(`${this.tag_name} Quiz`);
    this.tid = this.route.snapshot.queryParams['tid'];
    if (this.tid) {
      this.fetchQuizAPIManager.setPathParams(this.tid);
    } else {
      this.fetchQuizAPIManager.setQueryString('tag_name', this.tag_name);
      this.fetchQuizAPIManager.setQueryString('limit', this.default_limit);
    }
    this.loadQuiz();
    this.correctSound = new Audio('./assets/audio/correct_answer.mp3');
    this.incorrectSound = new Audio('./assets/audio/incorrect_answer.mp3');
  }

  public loadQuiz() {
    this.loading = true;
    this.subscription.add(
      this.service.execute<Quiz>(this.fetchQuizAPIManager).subscribe(
        (response: ItemResponse<Quiz>) => {
          this.loading = false;
          this.submissiontoken = response.head.submissiontoken;
          const data = response.data;
          // Handled when user clear search before request finish | no search result
          if (data != null) {
            this.restart_timer();
            // this.start_count_down();
            data.questions.map((q) => Utils.shuffleArray(q.alternatives)); // randmize alts
            this.result = data;
          } else {
            this.result = null;
          }
          // this.ref.detectChanges
        },
        () => {
          this.loading = false;
          this.result = null;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
