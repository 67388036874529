import { Aqa } from './../model/aqa';
import { Mapper } from '../../conn';

export class AqaMapper implements Mapper<Aqa> {

    public static getInstance(): AqaMapper {
        return new AqaMapper();
    }

    fromJson(json: any): Aqa {
        const aqa = new Aqa();
        aqa.user_pos = json.user_pos;
        aqa.user_old_pos = json.user_old_pos;
        aqa.user_pos_gain = aqa.user_pos - aqa.user_old_pos;
        aqa.user_percentage = json.user_percentage;
        aqa.user_score = json.user_score;
        aqa.user_tag_score = json.user_tag_score;
        return aqa;
    }

    toJson(aqa: Aqa) {
        return {
        };
    }
}
