import { Mapper } from '../../conn';
import { Question } from '../model/question';
import { AltMapper } from './alt.mapper';
import { TagMapper } from './tag.mapper';
import { Tag } from '../model/tag';
import { Answer } from '../model/answer';
import { Alternative } from '../model/alternative';
import { UserMapper } from './user.mapper';
import { User } from '../model/user';
import { AnswerMapper } from './answer.mapper';
// {"qid":128139729,"qtext":"What country is 'ITWorx' from?","img_desc":null,"img_url":null,
// "uid":106292437,"category_id":102,"answer_time":null,
// "alternatives":[{"aid":128141497,"text":"Libya","status":0},...]}

// answer_time: null
// answers: (4) [{…}, {…}, {…}, {…}]
// author: {uid: 106292437, fn: "Aya", ln: "Nabil", avatar: null}
// cid: 120
// class: "A"
// create_date: "2015-03-25 15:32:55"
// extra_info: null
// extra_url: ""
// img_url: null
// language_id: 901
// pic: null
// qid: 128416314
// qt: "Which of these capital cities lies on the River Nile?"
// tags: (3) [{…}, {…}, {…}]
// total_taken: 37
// update_date: "2018-02-12 13:42:12"
// user: {qid: 128416314, uid: 10490581, aid: null, status: false, time: 31.99, …}
export class QuestionMapper implements Mapper<Question> {

    public static getInstance(): QuestionMapper {
        return new QuestionMapper();
    }

    fromJson(json: any): Question {
        const q = new Question();
        q.id = json.qid;
        // q.text = json.qtext;
        q.text = json.hasOwnProperty('qtext') ? json.qtext : json.qt;
        q.img_desc = json.img_desc;
        q.img_url = json.hasOwnProperty('img_url') ? json.img_url : json.pic_url;
        q.uid = json.uid;
        q.cp = json.cp;
        q.tk = json.tk;
        q.hearted = json.hearted;
        q.class = json.class;
        q.category_id = json.category_id;
        q.answer_time = json.answer_time;
        // Convert json array of alts array of objs.
        if (json.hasOwnProperty('ans')) {
            const altMapper = new AltMapper();
            const alts: Alternative[] = json.ans.map((alt: any) => altMapper.fromJson(alt));
            q.alternatives = alts;
        }
        // Convert json array of answers array of objs.
        if (json.hasOwnProperty('answers')) {
            const ansMapper = new AnswerMapper();
            const answers: Answer[] = json.answers.map((ans: any) => ansMapper.fromJson(ans));
            q.answers = answers;
        }
        // Convert json array of tags array of objs.
        if (json.hasOwnProperty('tags')) {
            const tagMapper = new TagMapper();
            const tags: Tag[] = json.tags.map((tag: any) => tagMapper.fromJson(tag));
            q.tags = tags;
        }
        // Convert json user object
        if (json.hasOwnProperty('user')) {
            q.user = new UserMapper().fromJson(json.user);
        }

        // resolve user answer index.
        if (q.user && q.answers) {
            q.user.answerIndex = q.answers.findIndex(ans => ans.id === q.user.answerId);
        }
        return q;
    }

    toJson(q: Question) {
        const altMapper = new AltMapper();
        return {
            qid: q.id,
            qtext: q.text,
            // Convert alt array to json array
            alternative: q.alternatives.map(alt => altMapper.toJson(alt))
        };
    }
}
