import { Component, OnInit } from '@angular/core';
import { WOKConnection, ListResponse, APIManager, ConfigService } from '../../conn';
import { WMApi, QuizMapper, MenuItems, Quiz } from '../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../../environments/config';


@Component({
  selector: 'app-me-quizzes',
  templateUrl: './me-quizzes.component.html',
  styleUrls: ['./me-quizzes.component.css']
})
export class MeQuizzesComponent implements OnInit {
  public apiManager: APIManager<Quiz>;
  quizzes: Quiz[];
  loading: boolean;
  hasChallenge: number;
  icon: string;
  constructor(private service: WOKConnection,
    route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService) {
    this.apiManager = new APIManager<Quiz>(WMApi.ME_QUIZZES, QuizMapper.getInstance());
    const tid = route.snapshot.queryParams['tid'];
    this.icon = configService.getConfig()[config.TAG_ICON];
    this.apiManager.setPathParams(tid);
    this.listMeQuizzes();
  }

  ngOnInit() {
  }

  listMeQuizzes() {
    this.loading = true;
    this.service.execute(this.apiManager).subscribe((response: ListResponse<Quiz>) => {
      if (response && response.data) {
        this.quizzes = this.quizzes && this.apiManager.append
          ? this.quizzes.concat(response.data)
          : response.data;
      } else {
        this.quizzes = response.data;
      }
      this.loading = false;
    });
  }

  loadNext() {
    this.listMeQuizzes();
  }

  onReview(quiz: Quiz) {
    this.configService.setOption(config.QUIZ_NAME, quiz.name);
    this.router.navigate([], {
      fragment: MenuItems[MenuItems.MENU_REVIEW_QUIZ],
      queryParams: { qzid: quiz.id }
    });
  }
}
