<div class="center-abs">
    <div class="blue_bg">
        <div class="mx-auto py-3 clearfix">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input type="email" formControlName="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div class="yellow" *ngIf="f.email.errors.required">Email is required</div>
                        <div class="yellow" *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" placeholder="Password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div class="yellow" *ngIf="f.password.errors.required">Password is required</div>
                        <div class="yellow" *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <span class="float-left white pointer">Forget Password</span>

                <div class="form-group">
                    <button type="submit" class="btn blue white_bg float-right">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
