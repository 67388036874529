import { Injectable, TemplateRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ConfigService {
  private config = {};
  private subject = new Subject<string | TemplateRef<any> | boolean>();

  setAlert(body: string | TemplateRef<any> | boolean) {
    this.subject.next(body);
  }

  getAlert(): Observable<string | TemplateRef<any> | boolean> {
    return this.subject;
  }

  setOption(key: string, value: any) {
    this.config[key] = value;
  }
  getConfig() {
    return this.config;
  }
}
