import { NgModule } from '@angular/core';
import { CqComponent, Cq1Component, Cq2Component, Cq3Component, Cq4Component, Cq5Component } from '../cq';

const components = [
  CqComponent,
  Cq1Component,
  Cq2Component,
  Cq3Component,
  Cq4Component,
  Cq5Component
];

@NgModule({
  declarations: components,
  exports: [CqComponent]
})
export class QuestionModule {

}
