import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  APIManager,
  ConfigService,
  ListResponse,
  WOKConnection,
} from '../../conn';
import { Question, Tag, UserManager, Utils, WMApi } from '../../core';
import { QuestionMapper } from './../../core/mapper/question.mapper';
import { config } from 'src/environments/config';

@Component({
  selector: 'app-check-answer',
  templateUrl: './check-answer.component.html',
  styleUrls: ['./check-answer.component.css'],
})
export class CheckAnswerComponent implements OnInit {
  allSelected = 0;
  correctSelected = 1;
  incorrectSelected = -1;
  viewMode: number;
  private apiManager: APIManager<Question>;
  result: Question[];
  selectedQuestions: Question[];
  loading: boolean;
  hasChallenge: number;
  qzid: number;
  index = 0;
  total: number;
  userAvatar: string;
  allCorrect = false;
  allInCorrect = false;

  constructor(
    private service: WOKConnection,
    route: ActivatedRoute,
    private router: Router,
    userManager: UserManager,
    configService: ConfigService
  ) {
    this.apiManager = new APIManager<Question>(
      WMApi.CHECK_ANSWER,
      QuestionMapper.getInstance()
    );
    this.qzid = route.snapshot.queryParams['qzid'];
    const apiLink = configService.getConfig()[config.API_LINK];
    if (apiLink) {
      this.apiManager.$page = { next: apiLink };
    } else {
      this.apiManager.setPathParams(this.qzid);
    }
    userManager.getUser().subscribe((user) => (this.userAvatar = user.avatar));
    this.viewMode = this.allSelected;
  }
  ngOnInit() {
    this.loading = true;
    this.listQuestions();
  }

  listQuestions() {
    this.loading = true;
    this.service
      .execute(this.apiManager)
      .subscribe((response: ListResponse<Question>) => {
        if (response && response.data) {
          this.result =
            this.result && this.apiManager.append
              ? this.result.concat(response.data)
              : response.data;
          this.total = this.result.length;
        }
        this.prepareSelectedQuestions();
        this.loading = false;
      });
  }

  prepareSelectedQuestions() {
    const correctList = this.result.filter((qt) => qt.user && qt.user.status);
    const inCorrectList = this.result.filter(
      (qt) => !(qt.user && qt.user.status)
    );
    this.selectedQuestions =
      this.viewMode === this.allSelected
        ? this.result
        : this.viewMode === this.incorrectSelected
        ? inCorrectList
        : correctList;
    if (!this.apiManager.append) {
      this.index = 0;
    }
    this.allCorrect = correctList.length === this.result.length;
    this.allInCorrect = inCorrectList.length === this.result.length;
    this.total = this.selectedQuestions.length;
  }

  next() {
    if (this.index !== this.selectedQuestions.length - 1) {
      this.index++;
    } else if (this.apiManager.canLoadMore) {
      this.listQuestions();
    }
  }

  previous() {
    if (this.index > 0) {
      this.index--;
    }
  }

  openWikiTag(tag: Tag) {
    this.router.navigate([tag.lngAbbr, Utils.wikipediaURIFormat(tag.name)], {
      fragment: null,
      queryParams: null,
    });
  }

  onTapSelected(mode: number) {
    if (this.viewMode !== mode) {
      this.viewMode = mode;
      this.prepareSelectedQuestions();
    }
  }
}
