import { Mapper } from '../../conn';
import { Answer } from '../model/answer';

export class AnswerMapper implements Mapper<Answer> {
    // 0: {answer_id: 130611338, answer_text: "Urinate", percentage: 63, status: true}
    fromJson(json: any): Answer {
        const ans = new Answer();
        ans.id = json.answer_id;
        ans.text = json.answer_text;
        ans.status = json.status;
        ans.percentage = json.percentage;
        return ans;
    }

    toJson(ans: Answer) {
        return {
            aid: ans.id,
            text: ans.text,
            status: ans.status
        };
    }
}
