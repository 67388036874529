import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { NgModule } from '@angular/core';
import { config } from 'src/environments/config';
import {
  AUTH_SERVICE,
  ConnectionLayerModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from '../conn';
import { UserManager } from '../core';
import { AuthenticationService } from './authentication.service';

export function interceptorFactory(
  authenticationService: AuthenticationService
) {
  return authenticationService;
}

@NgModule({
  imports: [ConnectionLayerModule, SocialLoginModule],
  providers: [
    UserManager,
    AuthenticationService,
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: 'user/login' },
    {
      provide: AUTH_SERVICE,
      deps: [AuthenticationService],
      useFactory: interceptorFactory,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(config.googleLoginProvider),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(config.facebookLoginProvider),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
})
export class AuthenticationModule {}
