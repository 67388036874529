<ng-template #template>
  <div class="modal-header py-3">
    <img src="./assets/img/WM_logo.png">
    <h4 class="modal-title pull-left ml-3">WikiMaster</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="textBody; else template_body">
      {{alerBody}}
    </div>
  </div>
</ng-template>

<ng-template #template_body>
  <div [ngTemplateOutlet]="alerBody"></div>
</ng-template>