import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
        // Encode parentheses
        url = url.replace(/\(/g, '%28')  // ( => %28
            .replace(/\)/g, '%29')       // ) => %29
            .replace(/\./g, '%2E')       // . => %2E
            .replace(/\-/g, '%2D');      // - => %2E

        // Use the default serializer.
        return this._defaultUrlSerializer.parse(url);
    }

    serialize(tree: UrlTree): string {
        return decodeURI(this._defaultUrlSerializer.serialize(tree)
            .replace(/%28/g, '(')
            .replace(/%29/g, ')')
            .replace(/%2E/g, '.')
            .replace(/%2D/g, '-'));
    }
}
