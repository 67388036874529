import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Resource } from './handler/resource';
import { BaseResponse } from './handler/response';
import { APIMethod } from './handler/api.method';
import { APIManager } from './handler/api.manager';
import { ListResponse } from './handler/list.response';
import { ItemResponse } from './handler/item.response';
import { ConfigService } from './config.service';
import { Head } from './handler/head';
import { config } from '../../environments/config';
import { ListMoreResponse } from './handler/list.response.more';
import { More } from './handler/more';
import { ItemMoreResponse } from './handler/item.response.more';

@Injectable()
export class WOKConnection {
  private BASE_URL: string;

  /**
   * Construct connection instance to communicate with API (CRUD)
   * @param httpClient angular default http client instance.
   */
  constructor(private httpClient: HttpClient,
    private configService: ConfigService) {
    this.BASE_URL = environment.apiUrl;
  }

  public execute<T extends Resource>(apiManager: APIManager<T>): Observable<BaseResponse> {
    return this.executeMore<T, More>(apiManager);
  }

  public executeMore<T extends Resource, M extends More>(apiManager: APIManager<T>): Observable<BaseResponse> {
    switch (apiManager.api.method) {
      case (APIMethod.FETCH):
        return this.fetch<T, M>(apiManager);
      case (APIMethod.LIST):
        return this.list<T, M>(apiManager);
      case (APIMethod.POST):
        return this.post<T>(apiManager);
      case (APIMethod.PUT):
        return this.update<T>(apiManager);
      case (APIMethod.DELETE):
        return this.delete<T>(apiManager);
    }
  }

  private post<T extends Resource>(apiManager: APIManager<T>): Observable<ItemResponse<T>> {
    return this.httpClient
      .post<BaseResponse>(`${this.BASE_URL}/${apiManager.api.endpoint}${apiManager.$pathParams}`, apiManager.$bodyParam,
        { headers: apiManager.getHeaders() })
      .pipe(tap(response => {
        this.resolveError(response.head);
        if (apiManager.mapper && response.data) {
          response.data = apiManager.mapper.fromJson(response.data);
        }
      }));
  }

  private update<T extends Resource>(apiManager: APIManager<T>): Observable<ItemResponse<T>> {
    return this.httpClient
      .put<BaseResponse>(`${this.BASE_URL}/${apiManager.api.endpoint}${apiManager.$pathParams}`, apiManager.$bodyParam,
        { headers: apiManager.getHeaders() })
      .pipe(tap(response => {
        this.resolveError(response.head);
        if (apiManager.mapper && response.data) {
          response.data = apiManager.mapper.fromJson(response.data);
        }
      }));
  }

  private fetch<T extends Resource, M extends More>(apiManager: APIManager<T>): Observable<ItemResponse<T> | ItemMoreResponse<T, M>> {
    return this.httpClient
      .get<BaseResponse>(`${this.BASE_URL}/${apiManager.api.endpoint}${apiManager.$pathParams}${apiManager.$queryString}`,
        { headers: apiManager.getHeaders() })
      .pipe(tap(response => {
        this.resolveError(response.head);
        if (apiManager.mapper && response.data) {
          response.data = apiManager.mapper.fromJson(response.data);
        }
      }));
  }

  private list<T extends Resource, M extends More>(apiManager: APIManager<T>): Observable<ListResponse<T> | ListMoreResponse<T, M>> {
    const url = apiManager.getListURL(this.BASE_URL);
    if (url) {
      return this.httpClient
        .get<BaseResponse>(url, { headers: apiManager.getHeaders() })
        .pipe(tap(response => {
          this.resolveError(response.head);
          if (apiManager.mapper && response.data) {
            response.data = response.data.map((item: any) => apiManager.mapper.fromJson(item));
          }
          // Update page reference on api manager to handle next page.
          apiManager.$page = response.more ? response.more.page : null;
        }));
    } else {
      // When pagination and no more pages.
      return of({ head: { status: 0, msg: 'No more page available' } } as ListResponse<T>);
    }
  }

  /**
   * Check if req fail and display error msg.
   * @param head reponse head obj
   */
  private resolveError(head: Head) {
    if (-2 === head.status) { // NW not allowed.
      this.configService.setAlert(config.NW_MSG);
    } else if (-1 === head.status) {
      this.configService.setAlert(head.msg);
    }
  }

  private delete<T extends Resource>(apiManager: APIManager<T>): Observable<BaseResponse> {
    return this.httpClient.delete<BaseResponse>(`${this.BASE_URL}/${apiManager.api.endpoint}${apiManager.$pathParams}`,
      { headers: apiManager.getHeaders() });
  }

  public loadResource(path: string, options?: {}): Observable<any> {
    return this.httpClient.get(path, options);
  }
}
