import { EotComponent } from './eot/eot.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TakeQuizComponent } from './take-quiz/take-quiz.component';
import { WikiComponent } from './wiki/wiki.component';
import { config } from '../environments/config';




const appRoutes: Routes = [
    {
        path: '',
        component: WikiComponent
    },

    {
        path: ':language/:wiki_name',
        component: WikiComponent
    },
    {
        path: ':language',
        component: WikiComponent
    },
    {
        path: ':language/:wiki_name/quiz',
        component: TakeQuizComponent
    },
    {
        path: ':language/:wiki_name/quiz/finish',
        component: EotComponent
    },
    // <--When not match any path just redirect to Empty will handle inside wiki component
    {
        path: '**', component: WikiComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes,
            // { enableTracing: true } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
