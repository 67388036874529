<div class="center-abs">
  <div class="blue_bg">
    <div class="mx-auto py-3">
      <div class="blue_bg w-100 rounded white_border d-table my-2 pointer" (click)="onSelect(menuItem.MENU_LOGIN_FORM)">
        <span class="d-table-cell align-middle pl-2">
          <img src="./assets/img/w-white.png" />
        </span>
        <h4 class="text-center d-table-cell white align-middle p-3">Login to WOK</h4>
      </div>
      <div class="w-100 rounded white_border white_bg d-table my-2 pointer" (click)="onSelect(menuItem.MENU_SIGNUP_FORM)">
        <span class="d-table-cell align-middle pl-2">
          <img src="./assets/img/w-green.png" />
        </span>
        <h4 class="green text-center d-table-cell align-middle p-3">Sign up for FREE!</h4>
      </div>
      <div (click)="loginByFacebook()" class="w-100 rounded white_border white_bg d-table my-2 pointer">
        <span class="d-table-cell align-middle pl-2">
          <img src="./assets/img/fb.png" />
        </span>
        <h4 class="blue text-center d-table-cell  align-middle p-3">Facebook Sign Up / Login</h4>
      </div>
      <div (click)="loginByGoogle()" class="w-100 rounded white_border white_bg d-table my-2 pointer">
        <span class="d-table-cell align-middle pl-2">
          <img src="./assets/img/google.png" />
        </span>
        <h4 class="blue text-center d-table-cell align-middle p-3">Sign in with Google</h4>
      </div>
    </div>
  </div>
</div>
