import { API, APIMethod } from '../../conn';

export class WMApi {
    public static SERACH_TAG: API = { endpoint: 'v2.7/test/nk3', method: APIMethod.LIST };
    public static GENERATE_QUIZ_QUESTIONS: API = { endpoint: 'v2.7/test/nk3/generate/wikipedia', method: APIMethod.LIST };
    public static START_QUIZ: API = { endpoint: 'v2.7/ww/quiz/start/me', method: APIMethod.FETCH };

    // User login api.
    public static USER_LOGIN: API = { endpoint: 'v2.7/user/login', method: APIMethod.POST };
    // Add new user
    public static USER_SIGNUP: API = { endpoint: 'v2.7/user/signup', method: APIMethod.POST };
    public static NW_REGISTER: API = { endpoint: 'v2.7/user/signup/nw', method: APIMethod.POST };

    // Join NW by
    public static USER_JOIN_SIGN_UP: API = { endpoint: 'v2.7/user/join', method: APIMethod.POST };
    public static USER_JOIN_FB: API = { endpoint: 'v2.7/user/join/FB', method: APIMethod.POST };
    public static USER_JOIN_GOOGLE: API = { endpoint: 'v2.7/user/join/Google', method: APIMethod.POST };
    // Facebook SignUp
    public static USER_SIGNUP_FB: API = { endpoint: 'v2.7/user/signup/FB', method: APIMethod.POST };
    // Google login
    public static USER_SIGNUP_GOOGLE: API = { endpoint: 'v2.7/user/signup/Google', method: APIMethod.POST };

    // Auth APIs request token
    public static REQUEST_TOKEN: API = { endpoint: 'v2.7/user/token', method: APIMethod.POST };
    public static REQUEST_TOKEN_FB: API = { endpoint: 'v2.7/user/token/fb', method: APIMethod.POST };
    public static REQUEST_TOKEN_GOOGLE: API = { endpoint: 'v2.7/user/token/google', method: APIMethod.POST };

    // LQ
    public static LQ_LATEST: API = { endpoint: 'v2.7/ww/latest/me', method: APIMethod.LIST };
    public static LQ_POPULAR: API = { endpoint: 'v2.7/ww/popular/me', method: APIMethod.LIST };
    public static LQ_MAIN: API = { endpoint: 'v2.7/ww/subject/me', method: APIMethod.LIST };
    public static LQ_ME: API = { endpoint: 'v2.7/ww/me', method: APIMethod.LIST };

    // RQ
    public static ME_QUIZZES: API = { endpoint: 'v2.7/ww/tag/progress/me', method: APIMethod.LIST };
    public static QUIZ_REVIEW: API = { endpoint: 'v2.7/ww/quiz/review/me', method: APIMethod.FETCH };
    public static CHECK_ANSWER: API = { endpoint: 'v2.7/ww/quiz/questions/review/me', method: APIMethod.LIST };

    // submit Quiz answer
    public static QUIZ_ANSWER_SUBMIT: API = { endpoint: 'v2.7/ww/quiz/new/me', method: APIMethod.POST };

    // tag details
    public static TAG_DETAILS: API = { endpoint: 'v2.7/ww/tag/details/wikipedia/me', method: APIMethod.FETCH };

    // EOT
    public static EOT: API = { endpoint: 'v2.7/ww/AQA/me', method: APIMethod.FETCH };

    // WWTQ
    public static WWTQ: API = { endpoint: 'v2.7/ww/tag/users/me', method: APIMethod.LIST };

    // EQ
    public static EQ_CREATED: API = { endpoint: 'v2.6/ww/list/questions/me', method: APIMethod.LIST };
    public static EQ_TAKEN: API = { endpoint: 'v2.7/question/taken/me', method: APIMethod.LIST };
}
