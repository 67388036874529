import { Resource } from '../../conn';
import { Alternative } from './alternative';
import { Answer } from './answer';
import { Tag } from './tag';
import { User } from './user';
export class Question implements Resource {

  tk: number;
  cp: number;
  hearted: boolean;
  class: string;
  id: number;
  text: string;
  img_desc: string;
  img_url: string;
  icon: string;
  uid: number;
  category_id: number;
  answer_time: number;
  alternatives: Alternative[];
  answers: Answer[];
  tags: Tag[];
  user: User;
}
