<div class="center-abs container" id="lq">
  <nav class="sticky-top white_bg navbar navbar-expand px-0">
    <div class="collapse navbar-collapse tabs">
      <ul class="navbar-nav mr-auto w-100 w-100">
        <li class="nav-item active" [class.active]="visibilityOf(menuItem.MENU_LQ_LATEST)" rel="latest"
          (click)="onSelect(menuItem[menuItem.MENU_LQ_LATEST])">
          <a class="nav-link pointer">Latest</a>
        </li>
        <li class="nav-item" [class.active]="visibilityOf(menuItem.MENU_LQ_POPULAR)" rel="popular"
          (click)="onSelect(menuItem[menuItem.MENU_LQ_POPULAR])">
          <a class="nav-link pointer">Popular</a>
        </li>

        <li class="nav-item" [class.active]="visibilityOf(menuItem.MENU_LQ_MAIN)" rel="main"
          (click)="onSelect(menuItem[menuItem.MENU_LQ_MAIN])">
          <a class="nav-link pointer">Main</a>
        </li>

        <li class="nav-item" [class.active]="visibilityOf(menuItem.MENU_LQ_ME)" rel="me"
          (click)="onSelect(menuItem[menuItem.MENU_LQ_ME])">
          <a class="nav-link pointer">Me</a>
        </li>
      </ul>
    </div>
  </nav>
  <ul class="list-group list-unstyled">
    <li *ngFor="let tag of tags" class="lq_row my-3 clearfix">
      <div class="w-25 pr-1 float-left h-100 d-flex align-items-center justify-content-center">
        <img class="pointer" src={{tag.icon}} onError="this.src='./assets/img/wikimaster_logo.png'">
      </div>
      <div class="w-75 d-flex align-items-start flex-column h-100 float-right">
        <div class="mb-auto" (click)="openTag(tag)">
          <h5 class="blue pointer">{{tag.name}}</h5>
        </div>
        <div class="w-100 d-flex justify-content-between">

          <!-- WOKers who took question from this tag -->
          <div (click)="openTagWOKers(tag)" class="pointer col-1 p-0 d-flex align-items-end">
            <img class="w-80" src="./assets/img/woker_alias.png" />
          </div>
          <div (click)="openTagWOKers(tag)" class="pointer d-flex align-items-end grey col-2 p-0">
            <span class="total-users">{{kFormatter(tag.totalUser)}}</span>
          </div>

          <div class="d-flex align-items-end green col-3 p-0">
            <span class="total-qs">
              {{kFormatter(tag.totalAnsweredQsByUserInTag)}}/{{kFormatter(tag.totalQuestion)}}</span></div>
          <div class="col-2 p-0 text-center d-flex align-items-end justify-content-end">
            <img *ngIf="tag.favorite" class="w-40 pointer" src="./assets/img/fav-yellow.png" />
            <img *ngIf="!tag.favorite" class="w-40 pointer" src="./assets/img/favorite_golden.png" />
          </div>
          <div class="col-2 p-0 text-center d-flex align-items-end justify-content-end" (click)="onReview(tag)">
            <img *ngIf="tag.totalAnsweredQsByUserInTag > 0" class="w-50 pointer" src="./assets/img/view-blue-lq.png" />
          </div>
          <div class="col-2 p-0 text-center d-flex align-items-end justify-content-end" (click)="onPlay(tag)">
            <img class="w-40 pointer" src="./assets/img/tq-blue.png" />
          </div>
        </div>
      </div>
      <div class="w-100 hr"></div>
    </li>
  </ul>

  <div *ngIf="loading || apiManager.canLoadMore" class="text-center col-md-8 col-12 my-3 mx-auto">
    <img *ngIf="loading" class="border-0 w-25" src="./assets/img/loading1.gif" />
    <button *ngIf="!loading" class="blue pointer rounded blue_bg white" (click)="loadNext()">Show More</button>
  </div>
</div>