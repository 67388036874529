import { Resource } from './resource';
import { Mapper } from './mapper';
import { HttpHeaders } from '@angular/common/http';
import { API } from './api';
import { Page } from './page';
export class APIManager<T extends Resource | undefined> {
    private queryString: Map<string, any>;
    private pathParams: string;
    private bodyItem: T;
    private page: Page;

    constructor(public api: API, public mapper: Mapper<T>) {
        this.queryString = new Map<string, any>();
    }

    public setQueryString(key: string, value: any) {
        this.queryString[key] = value;
    }

    public get $queryString(): string {
        const queryString = this.buildQueryString();
        return queryString === '' ? '' : `?${queryString}`;
    }

    private buildQueryString(): string {
        return Object
            .keys(this.queryString)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.queryString[key])}`)
            .join('&');
    }

    public setPathParams(params: string | number | (string | number)[]) {
        if (params instanceof Array) {
            this.pathParams = '/' + params.map(param => `${encodeURIComponent(`${param}`)}`).join('/');
        } else {
            this.pathParams = `/${encodeURIComponent(`${params}`)}`;
        }
    }

    public get $pathParams(): string {
        return this.pathParams != null ? this.pathParams : '';
    }

    /**
     * Setter $bodyItem
     * @param {T} value
     */
    public setbodyItem(value: T) {
        this.bodyItem = value;
    }

    public get $bodyParam(): string {
        return this.bodyItem != null ? this.mapper.toJson(this.bodyItem) : this.buildQueryString();
    }

    public getHeaders(): HttpHeaders {
        if (this.bodyItem != null) {
            // Json body when token extists
            return new HttpHeaders()
                .set('Content-Type', 'application/json; charset=utf-8');
        } else {
            // Body param
            return new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded');
        }
    }

    public get canLoadMore(): boolean {
        return (this.page && this.page.next && 0 !== this.page.next.length);
    }

    public get append(): boolean {
        return (this.page && this.page.current_page > 1);
    }

    /**
     * Getter $page
     * @return {Page}
     */
    public get $page(): Page {
        return this.page;
    }

    /**
     * Setter $page
     * @param {Page} value
     */
    public set $page(value: Page) {
        this.page = value;
    }

    public getListURL(BASE_URL: string): string {
        if (!this.page) {
            return `${BASE_URL}/${this.api.endpoint}${this.$pathParams}${this.$queryString}`;
        } else if (this.page.next) {
            return this.page.next;
        }
    }
}
