import { Mapper } from '../../conn';
import { Alternative } from '../model/alternative';

export class AltMapper implements Mapper<Alternative> {
    // {"aid":128141497,"text":"Libya","status":0}
    fromJson(json: any): Alternative {
        const alt = new Alternative();
        alt.id = json.aid;
        alt.text = json.atext;
        alt.status = json.status;
        return alt;
    }

    toJson(alt: Alternative) {
        return {
            aid: alt.id,
            text: alt.text,
            status: alt.status
        };
    }
}
