import { Resource } from '../../conn';
export class Aqa implements Resource {
  user_pos_gain: number;
  id: number;
  quiz_percentage: number;
  tag_percentage: number;
  tag_q_position: number;
  tk: number;
  tq: number;
  user_old_pos: number;
  user_percentage: number;
  user_pos: number;
  user_score: number;
  user_tag_score: number;
  wokbits: number;
}
