import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItems, WMApi, Tag, TagMapper, Quiz, Utils } from '../core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIManager, WOKConnection, ListResponse, ConfigService } from '../conn';
import { Subscription } from 'rxjs';
import { config } from '../../environments/config';

@Component({
  selector: 'app-lq',
  templateUrl: './lq.component.html',
  styleUrls: ['./lq.component.css']
})
export class LqComponent implements OnInit, OnDestroy {
  fragment: string;
  menuItem = MenuItems;
  tags: Tag[];
  loading: boolean;
  private tagMapper: TagMapper;
  private subscription: Subscription;
  public apiManager: APIManager<Tag>;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private service: WOKConnection,
    private configService: ConfigService) {
    this.tagMapper = TagMapper.getInstance();
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.subscription.add(this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      this.tags = null; // clear tags when switch to another tab.
      this.displayLQ();
    }));
  }

  visibilityOf(item: string): boolean {
    return this.fragment === item;
  }

  onSelect(tap: string) {
    this.fragment = tap;
    this.router.navigate([], { fragment: this.fragment });
  }

  displayLQ() {
    switch (this.fragment) {
      case (MenuItems.MENU_LQ_LATEST):
        this.listTags(new APIManager<Tag>(WMApi.LQ_LATEST, this.tagMapper));
        break;
      case (MenuItems.MENU_LQ_POPULAR):
        this.listTags(new APIManager<Tag>(WMApi.LQ_POPULAR, this.tagMapper));
        break;
      case (MenuItems.MENU_LQ_MAIN):
        this.listTags(new APIManager<Tag>(WMApi.LQ_MAIN, this.tagMapper));
        break;
      case (MenuItems.MENU_LQ_ME):
        this.listTags(new APIManager<Tag>(WMApi.LQ_ME, this.tagMapper));
        break;
    }
  }

  listTags(apiManager: APIManager<Tag>) {
    this.loading = true;
    this.apiManager = apiManager;
    this.subscription.add(this.service.execute(apiManager).subscribe((response: ListResponse<Tag>) => {
      if (response && response.data) {
        this.tags = this.tags && apiManager.append
          ? this.tags.concat(response.data)
          : response.data;
      }
      this.loading = false;
    }));
  }

  onReview(tag: Tag) {
    this.setTagItem(tag);
    this.router.navigate([tag.lngAbbr, Utils.wikipediaURIFormat(tag.name)], {
      fragment: MenuItems[MenuItems.MENU_REVIEW_ME_QUIZZES],
      queryParams: { tid: tag.id }
    });
  }

  onPlay(tag: Tag) {
    this.setTagItem(tag);
    this.router.navigate([tag.lngAbbr, Utils.wikipediaURIFormat(tag.name), 'quiz'], {
      fragment: null,
      queryParams: { tid: tag.id }
    });
  }

  openTag(tag: Tag) {
    this.setTagItem(tag);
    this.router.navigate([tag.lngAbbr, Utils.wikipediaURIFormat(tag.name)], {
      fragment: null,
      queryParams: null
    });
  }

  openTagWOKers(tag: Tag) {
    this.setTagItem(tag);
    this.router.navigate([tag.lngAbbr, Utils.wikipediaURIFormat(tag.name)], {
      fragment: MenuItems[MenuItems.MENU_TOTAL_QS],
      queryParams: { tid: tag.id }
    });
  }

  setTagItem(tag: Tag) {
    this.configService.setOption(config.TAG_ICON, tag.icon);
    localStorage.setItem(config.language, tag.lngAbbr);
    localStorage.setItem(config.wikiNameParamKey, tag.name);
  }

  loadNext() {
    this.listTags(this.apiManager);
  }
  kFormatter(num: number) {
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
