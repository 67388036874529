import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { config } from '../../environments/config';
import {
  APIManager,
  ConfigService,
  ItemResponse,
  WOKConnection,
} from '../conn';
import {
  MenuItems,
  SiteUtils,
  Tag,
  TagMapper,
  User,
  UserManager,
  Utils,
  WMApi,
} from '../core';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.css'],
})
export class WikiComponent implements OnInit, OnDestroy {
  @ViewChild('search_input') searchInput: ElementRef;
  @ViewChild('logOut_temp') logOut: TemplateRef<any>;

  flashing = true;
  hideMenu: boolean;
  overLayWidth: number;
  SiteUtils = SiteUtils;
  user: Observable<User>;
  wiki_content: SafeHtml;
  tag_name: string;
  wiki_name: string;
  loading: boolean;
  totalQsTaken: string | number;
  totalQs: number | string;
  public results: Tag[];
  private fetchAPIManager: APIManager<Tag>;
  // Store a reference to the enum
  menuItem = MenuItems;
  searchTerm$ = new Subject<{ term: string; gpsoffset?: number }>();
  pages: any[];
  gpsoffset: number;
  fragment: string;
  selectedLQ: string;
  today: string;
  subscription: Subscription;
  tag: Tag;
  isMobile: boolean;
  language: string;
  languageChanged: boolean;
  notFoundError: boolean;
  pathRewrite: boolean;

  // test/nk3
  constructor(
    private service: WOKConnection,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userManager: UserManager,
    private configService: ConfigService,
    private titleService: Title,
    private meta: Meta,
  ) {
    this.isMobile = Utils.detectmob();
    this.fetchAPIManager = new APIManager(
      WMApi.TAG_DETAILS,
      TagMapper.getInstance()
    );

    /**
     * Initialize @subscription
     */
    this.subscription = new Subscription();
    /**
     * Handle wiki search @see Subject
     */
    this.subscription.add(
      this.search(this.searchTerm$)
        .pipe(
          map((res) =>
            res.query
              ? {
                  pages: Object.keys(res.query.pages)
                    .map((key) => res.query.pages[key])
                    .sort((p1, p2) => (p1.index < p2.index ? -1 : 1)),
                  gpsoffset: res.continue.gpsoffset,
                }
              : { pages: [], gpsoffset: undefined }
          )
        )
        .subscribe(
          (res: { pages: any[]; gpsoffset: number }) => {
            // Handled when user clear search before request finish | no search result
            this.pages = !this.gpsoffset
              ? res.pages
              : this.pages.concat(res.pages);
            this.gpsoffset = res.gpsoffset;
            this.loading = false;
          },
          () => {
            // On error
            this.loading = false;
            this.pages = null;
          }
        )
    );
    // Get user info.
    this.user = userManager.getUser();
    // initialize menu items.
    this.selectedLQ = this.menuItem[MenuItems.MENU_LQ_LATEST];
    this.today = new DatePipe('en-US').transform(
      new Date(),
      config.WIKI_REST_FEATURED_DATE_FORMAT
    );
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    // this.toggleMenuItem('');
    const path = event.data;
    if (
      event.origin === document.location.origin &&
      typeof path === 'string' &&
      path.includes('/')
    ) {
      this.navigate(path.substring(path.lastIndexOf('/') + 1));
    }
    this.flashing = true;
    this.flashingQs();
  }

  /**
   * Update wikipedia url.
   */
  getWikipediaContent() {
    this.service
      .loadResource(
        config.WIKI_REST_PAGE_HTML(
          this.language,
          this.wiki_name,
          this.isMobile
        ),
        { responseType: 'text' }
      )
      .subscribe(
        (response) => {
          const doc = new DOMParser().parseFromString(response, 'text/html');
          // remove en.wikipedia.org/wiki/ base url
          doc.head.getElementsByTagName('base')[0].href = `${this.language}/`;
          const wikiPageTitle = doc.head.getElementsByTagName('title')[0].text;
          if (wikiPageTitle && wikiPageTitle !== this.tag_name) {
            this.pathRewrite = true;
            this.tag_name = wikiPageTitle;
            this.navigate(Utils.wikipediaURIFormat(wikiPageTitle));
          }
          // this.meta.addTag({ base: 'base', content: 'en/' });

          // Add title section.
          if (!this.isMobile) {
            const title = doc.createElement('h1');
            title.innerText = this.tag_name;
            doc.body.insertAdjacentElement('afterbegin', title);
          }
          // Query img tag, that need wikipedia host and inject.
          Object.values(doc.querySelectorAll('img'))
            .filter((img) => img.src.includes('/w/'))
            .map((img) =>
              img.setAttribute(
                'src',
                `//wikipedia.org${img.getAttribute('src')}`
              )
            );

          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.setAttribute('src', '../assets/script/click_handler.js');
          doc.body.appendChild(script);

          // Query a tag, than handle extranal url to open on new tab (_blank).
          Object.values(doc.querySelectorAll('a')).map((link) => {
            if (link.href && link.href.includes('#')) {
              // remove hashable scrolling inside wikipedia page iframe until we find away to support on angular parent.
              link.remove();
            } else if (link.rel === 'mw:ExtLink') {
              link.setAttribute('target', '_blank');
            } else if (link.rel === 'mw:WikiLink/Interwiki') {
              link.href = `.${link.href
                .substring(link.href.lastIndexOf('/'))
                .split('%20')
                .join('_')}`;
              link.setAttribute('target', '_parent');
            } else {
              link.setAttribute('target', '_parent');
            }
          });

          /**
           * Remove none related  sections.
           */
          // const refs = doc.getElementById('References'); // remove references
          // if (refs) { refs.parentElement.remove(); }

          // const ext_link = doc.getElementById('External_links'); // remove external links
          // if (ext_link) { ext_link.parentElement.remove(); }

          // const notes = doc.getElementById('Notes'); // remove notes.
          // if (notes) { notes.parentElement.remove(); }

          // const notes2 = doc.getElementById('Notes_2'); // remove notes. Notes_2
          // if (notes2) { notes2.parentElement.remove(); }

          // const Further_reading = doc.getElementById('Further_reading');  // remove Further_reading
          // if (Further_reading) { Further_reading.parentElement.remove(); }

          // const WebsitesSection = doc.getElementById('Websites');  // remove Websites section
          // if (WebsitesSection) { WebsitesSection.parentElement.remove(); }

          // const Notes_and_references = doc.getElementById('Notes_and_references');  // remove Notes_and_references section
          // if (Notes_and_references) { Notes_and_references.parentElement.remove(); }

          this.wiki_content = this.sanitizer.bypassSecurityTrustHtml(
            doc.documentElement.innerHTML
          );
        },
        () => {
          if (this.languageChanged) {
            this.handleFeatureArticle();
          } else {
            this.notFoundError = true;
          }
        }
      );
  }

  /**
   * Parse wiki name from URL to change wikipedia (_) to space for WOK server request tag info.
   * @param pathName @see handleURLRoutes
   */
  parseWikiName(pathName: string): string {
    return decodeURI(pathName.split('_').join(' ')); // replace _ to space for WOK server tag details req.
  }

  ngOnInit() {
    this.handleURLRoutes();
    this.flashingQs();
  }

  openoLgout() {
    this.configService.setAlert(this.logOut);
  }

  hideLogOut() {
    this.configService.setAlert(false);
  }

  logout() {
    this.userManager.logout();
  }

  /**
   * let tag_name = this.route.snapshot.paramMap.get('wiki_name');  // get wikipedia name form url
   * use sunscribe when param maybe changed inside the page
   * (Can be a case when handle wikipedia click links inside iframe)
   */
  handleURLRoutes() {
    this.subscription.add(
      this.route.params.subscribe((params) => {
        const expectLang = params[config.language];
        const wikiName = params[config.wikiNameParamKey];
        if (!this.handleLanguage(expectLang, wikiName)) {
          return;
        }
        this.language = expectLang;
        if (wikiName) {
          // For Debug AS DecodeURI issue.
          if (wikiName.includes(' ')) {
            this.navigate(wikiName.split(' ').join('_'));
            return;
          }
          this.wiki_name = wikiName;
          this.tag_name = this.parseWikiName(wikiName);
          localStorage.setItem(config.wikiNameParamKey, this.tag_name);
          this.titleService.setTitle(this.tag_name);

          if (!this.pathRewrite) {
            this.getWikipediaContent();
            this.wiki_content = null;
            this.flashing = true;
            this.flashingQs();
          }
          this.fetchTagDetails();
          this.pathRewrite = false;
          this.notFoundError = false;
          this.pages = null;
        } else {
          this.handleFeatureArticle();
        }
      })
    );

    /**
     * Open hash element coming with url path
     */
    this.subscription.add(
      this.route.fragment.subscribe((fragment) => {
        this.toggleMenuItem(fragment, true); // hide open menu item.
      })
    );
  }

  handleLanguage(expectLang: string, wikiName: string): boolean {
    const lngid = config.language_map[expectLang];
    const storedLang = localStorage.getItem(config.language);
    const language = storedLang ? storedLang : config.language_default;

    if (lngid) {
      // Store language when it match our langauge schema.
      // language on path match our schema reconginzed language and continue.
      this.languageChanged = expectLang !== storedLang;
      localStorage.setItem(config.language, expectLang);
      return true;
    } else if (wikiName) {
      // url schema {not_match_lang}/{wikiName}
      // language not match our schema pass last choosed if exist or default with wikiName from url.
      this.router.navigate([language, wikiName]);
    } else if (!expectLang || expectLang.length === 2) {
      // schema {expect_lang}
      // language not match our schema pass last choosed if exist or default and
      // change router link to fetch feature with correct language.
      this.router.navigate([language]);
    } else {
      // schema {expect_wikiname}
      // language not match our schema pass last choosed if exist or default and
      // We expect language on path to be wikiName
      // change router link to {language} and expectedLang as {wiki_name}
      this.router.navigate([language, expectLang]);
    }
    return false;
  }

  fetchTagDetails() {
    this.fetchAPIManager.setPathParams([
      this.tag_name,
      config.language_map[this.language],
    ]);
    this.subscription.add(
      this.service.execute(this.fetchAPIManager).subscribe(
        (response: ItemResponse<Tag>) => {
          this.tag = response.data;
          this.totalQs = this.tag ? this.kFormatter(this.tag.totalQuestion) : 0;
          this.totalQsTaken = this.tag
            ? this.kFormatter(this.tag.totalAnsweredQsByUserInTag)
            : 0;
          if (this.tag && this.tag.icon) {
            this.meta.addTag({ name: 'og:image', content: this.tag.icon });
          }
        },
        () => {
          this.tag = null;
          this.totalQs = 0;
          this.totalQsTaken = 0;
        }
      )
    );
  }

  onReviewTag() {
    this.configService.setOption(config.TAG_ICON, this.tag.icon);
    const tqFragment = MenuItems[MenuItems.MENU_TOTAL_QS];
    if (!this.fragment || this.fragment !== tqFragment) {
      this.router.navigate([], {
        fragment: tqFragment,
        queryParams: { tid: this.tag.id },
      });
    } else {
      this.toggleMenuItem(tqFragment);
    }
  }

  onStartQuiz() {
    if (this.tag && this.tag.totalQuestion) {
      this.router.navigate([this.language, this.wiki_name, 'quiz'], {
        fragment: null,
        queryParams: { tid: this.tag.id },
      });
    } else {
      this.configService.setAlert(config.WIKI_ARTICLE_HAS_NO_QUESTION_MSG);
    }
  }

  handleFeatureArticle() {
    let tfa = localStorage.getItem(
      config.WIKI_REST_FEATURED_LOCAL_STORE_KEY(this.language, this.today)
    );
    // if(tfa) for current language not specified fetch from wikipedia feature and update localstorage
    if (tfa) {
      this.navigate(tfa);
    } else {
      this.subscription.add(
        this.getFeatured().subscribe((featured) => {
          // navigate to today feature article
          // tfa (Today featured article) not exists with some languages then
          // English default language use tfa
          // other languages take 1st one from onthisday.
          tfa =
            this.language === config.language_default
              ? featured.tfa.title
              : featured.onthisday[0].pages[0].title;
          this.navigate(tfa);
          // Store today feature to be reuse from storage.
          this.storeTFA(tfa);
        })
      );
    }
  }

  /**
   * Store Api response to be use later with page request from local storage.
   * Current implementation we use tfa as Today Featured article,
   * Later we may decide use random article from mostread, news or onthisday.
   * tfa: {type: "standard", title: "Reception_history_of_Jane_Austen", displaytitle: "Reception history of Jane Austen",..}
   * mostread: {date: "2019-01-20Z", articles: Array(30)}
   * news: (5) [{…}, {…}, {…}, {…}, {…}]
   * onthisday: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
   */
  storeTFA(tfa: string) {
    /**
     * Remove last stored days keep only today.
     */
    Object.keys(localStorage)
      .filter((key) =>
        key.includes(
          config.WIKI_REST_FEATURED_LOCAL_STORE_KEY(this.language, '')
        )
      )
      .map((key) => localStorage.removeItem(key));
    /**
     * Store today featured article tfa
     */
    localStorage.setItem(
      config.WIKI_REST_FEATURED_LOCAL_STORE_KEY(this.language, this.today),
      tfa
    );
  }

  /**
   * Handling search wiki article with debounceTime to elminate server requests.
   * @param terms search words typed by user.
   */
  search(terms: Observable<{ term: string; gpsoffset?: number }>) {
    return terms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        term.term.length !== 0
          ? this.searchEntries(term)
          : of({ continue: {}, query: { pages: {} } })
      )
    );
  }

  searchEntries(term: { term: string; gpsoffset?: number }) {
    this.loading = true;
    this.gpsoffset = term.gpsoffset;
    // this.listAPIManager.setQueryString('tag_name', term);
    // return this.service.execute(this.listAPIManager);
    return this.service.loadResource(
      config.WIKI_SEARCH_API(this.language, term.term, term.gpsoffset)
    );
  }

  // Handled menu tab
  toggleMenuItem(item: string, fromURL?: boolean, wikiName?: string) {
    if (!fromURL) {
      this.fragment = this.fragment !== item ? item : null;
      this.navigate(wikiName);
    } else {
      this.fragment = item;
    }
    this.checkLQSelected(); // save last selected LQ tab.
  }

  openMenu(menus: string | string[]): boolean {
    return menus instanceof Array
      ? menus.includes(this.fragment)
      : menus === this.fragment;
  }

  checkLQSelected() {
    if (
      this.fragment &&
      [
        this.menuItem[MenuItems.MENU_LQ_LATEST],
        this.menuItem[MenuItems.MENU_LQ_POPULAR],
        this.menuItem[MenuItems.MENU_LQ_MAIN],
        this.menuItem[MenuItems.MENU_LQ_ME],
      ].some((v) => v === this.fragment)
    ) {
      this.selectedLQ = this.fragment;
    }
  }

  onSearchTermSelected(term: any) {
    this.searchInput.nativeElement.value = '';
    this.loading = false;
    this.pages = null;
    this.toggleMenuItem(
      MenuItems[MenuItems.MENU_SEARCH],
      false,
      Utils.wikipediaURIFormat(term)
    );
  }

  private navigate(wiki?: string) {
    this.router.navigate(wiki ? [this.language, wiki] : [], {
      fragment: this.fragment,
      queryParams: this.fragment ? this.route.snapshot.queryParams : null,
    });
  }

  openLQ() {
    this.toggleMenuItem(this.selectedLQ);
  }

  public getFeatured(): Observable<any> {
    return this.service.loadResource(
      config.WIKI_REST_FEATURED(this.language, this.today)
    );
  }

  kFormatter(num: number) {
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
  }

  openOverlay() {
    this.overLayWidth = 100;
  }
  closeOverlay() {
    this.overLayWidth = 0;
  }
  fnNotReady() {
    this.configService.setAlert(config.EDIT_MSG);
  }
  toggleBMenu() {
    this.hideMenu = !this.hideMenu;
    this.toggleMenuItem(this.menuItem[this.menuItem.B_MENU]);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  swipeLanguage(language: string) {
    this.router.navigate([language, this.wiki_name]);
  }
  flashingQs() {
    setTimeout(() => {
      this.flashing = false;
    }, 1600);
  }
  onSelectLang() {
    this.toggleMenuItem(this.menuItem[this.menuItem.MENU_LANG]);
    this.hideMenu = !this.hideMenu;
  }
}
