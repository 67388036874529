import { User } from '../model/user';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { config } from '../../../environments/config';
import { UserMapper } from '../mapper/user.mapper';

export class UserManager {
    private userStorageKey = config.userStorageKey;

    getUser(): Observable<User> {
        const user = localStorage.getItem(this.userStorageKey);
        return of(user ? JSON.parse(user) : new User());
    }

    getAccessToken(): Observable<string> {
        return this.getUser().pipe(map(user => user.token));
    }

    setAccessToken(token: string) {
        this.getUser().pipe(
            tap((user: User) => user.token = token),
            tap((user: User) => this.save(user)))
            .subscribe();
    }

    save(user: User) {
        // store user details and token in local storage to keep user logged in between page refreshes
        localStorage.setItem(this.userStorageKey, JSON.stringify(user));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(this.userStorageKey);
        location.reload();
    }
}
