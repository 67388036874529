import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItems } from '../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  fragment: string;
  // Store a reference to the enum
  menuItem = MenuItems;
  subscription: Subscription;

  constructor(private route: ActivatedRoute) { }
  ngOnInit() {
    this.subscription = this.route.fragment.subscribe(fragment => this.fragment = fragment);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
