export const config = {
  userStorageKey: 'currentUser',
  wikipediaFeaturedArticleDateFormat: 'yyyyMMdd000000',
  wikiNameParamKey: 'wiki_name',
  language: 'language',
  language_default: 'en',
  language_map: { en: 901, ar: 2908, fr: 2909, sv: 2910 },
  // language_map:{en:901,ar:2908,fr:2909,sv:2910,de:2911,es:2912,
  // pt:2913,da:2914,fi:2915,no:2916,it:2917,ru:2918,hi:2919,nl:2920,pl:2921,el:2922,tr:2923},
  wikipediaFeaturedArticleFilePath: './assets/data/featured.json',
  facebookLoginProvider: '999447616748273',
  googleLoginProvider: '681713917811-mhs06783i2ipk9qd696fvc5o8qa3orj7.apps.googleusercontent.com',
  hashKeyName: 'hash',
  answerTime: 30,
  API_LINK: 'more_link',
  TAG_ICON: 'tag_icon',
  QUIZ_NAME: 'quiz_name',
  NW_MSG: 'This function is great when you log in as a registered WOKer and ' +
    'can enjoy many more functionalities in the Knowledge Network WOK.',
  EDIT_MSG: 'This function is not implemented yet.',
  WIKI_ARTICLE_HAS_NO_QUESTION_MSG: 'No questions connected with this article yet.',
  NONE_INTERCEPT_REQ: ['wikipedia.org'],

  WIKI_SEARCH_API: (languge: string, term: string, gpsoffset: number, limit?: number) => `https://${languge}.wikipedia.org/w/api.php`
    + `?action=query&generator=prefixsearch&origin=*&gpssearch=${term}&gpslimit=${limit ? limit : 10}`
    + `&prop=pageimages&redirects=&gpsoffset=${gpsoffset ? gpsoffset : 0}&format=json`,

  WIKI_REST_PAGE_HTML: (language: string, term: string, mobile?: boolean) => `https://${language}.wikipedia.org/api/rest_v1/`
    + `page/${!mobile ? 'html' : 'mobile-html'}/${term}`,

  WIKI_REST_FEATURED: (language: string, today: string) => `https://${language}.wikipedia.org/api/rest_v1/feed/featured/${today}`,
  WIKI_REST_FEATURED_DATE_FORMAT: 'yyyy/MM/dd',
  WIKI_REST_FEATURED_LOCAL_STORE_KEY: (language: string, today: string) => `featured/${language}/${today}`,
};
