import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ConfigService } from '../conn';
import { SiteUtils } from '../core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  @Input('shortFooter') shortFooter: boolean;
  @ViewChild('footer') footerModel: TemplateRef<any>;

  SiteUtils = SiteUtils;

  constructor(private configService: ConfigService) { }

  openFooter() {
    this.configService.setAlert(this.footerModel);
  }
}
