import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app.router';
import { CheckAnswerComponent } from './check-answer/check-answer.component';
import { MeQuizzesComponent } from './me-quizzes/me-quizzes.component';
import { ReviewQuizComponent } from './review-quiz/review-quiz.component';
import { ReviewChallengeComponent } from './review-challenge/review-challenge.component';
import { RqComponent } from './rq.component';
import { BrowserModule } from '@angular/platform-browser';


const components = [
    RqComponent,
    CheckAnswerComponent,
    MeQuizzesComponent,
    ReviewQuizComponent,
    ReviewChallengeComponent
];

@NgModule({
    declarations: components,
    exports: [RqComponent],
    imports: [
        BrowserModule,
        AppRoutingModule
    ]
})

export class RQModule {
}
