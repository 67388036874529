import { NgModule } from '@angular/core';
import {
  LoginComponent,
  SignupFormComponent,
  LoginFormComponent,
} from '../login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app.router';
import { LoginBtnsComponent } from './login-btns/login-btns.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginBtnsComponent,
    SignupFormComponent,
    LoginFormComponent,
  ],
  exports: [LoginComponent],
  imports: [BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule],
})
export class LoginModule {}
