import { Mapper } from '../../conn';
import { Tag } from '../model/tag';
import { config } from 'src/environments/config';
// {"tid":129659943,"tag_name":"Egypt","tqt":813}
export class TagMapper implements Mapper<Tag> {

    public static getInstance(): TagMapper {
        return new TagMapper();
    }

    fromJson(json: any): Tag {
        const tag = new Tag();
        tag.id = json.tid;
        tag.name = json.hasOwnProperty('tag_name') ? json.tag_name : json.tn;
        tag.totalAnsweredQsByUserInTag = json.hasOwnProperty('taquqz') && json.taquqz ? json.taquqz : 0;
        tag.favorite = json.favorite;
        tag.icon = json.th ? json.th.replace('http:', 'https:') : null;
        tag.totalQuestion = json.tqt;
        tag.lngid = json.lngid;
        tag.lngAbbr = json.hasOwnProperty('lng_abbr')
            ? json.lng_abbr
            : Object.keys(config.language_map).find(val => config.language_map[val] === tag.lngid);
        tag.totalUser = json.tut;
        return tag;
    }

    toJson(tag: Tag) {
        return {
            tid: tag.id,
            tag_name: tag.name,
            tqt: tag.totalQuestion
        };
    }
}
