<div class="container center-abs">

  <div class="mt-2 d-flex justify-content-between align-items-center text-center">
    <div class="text-left">
      <h3 class="green mb-0 px-2 font-weight-normal mb-3">Your Score</h3>
      <h6 class="green mb-0 px-2 font-weight-normal">The overall score in {{!quiz.name ? 'Quiz' : ''}}</h6>
      <h6 class="green font-14 mb-0 px-2 font-weight-normal">{{quiz.name}}</h6>
    </div>

    <div class="c100 p{{quiz.user_percentage ? quiz.user_percentage.toFixed() : 0}} m-0 score-circle">
            <span>{{quiz.user_percentage ? quiz.user_percentage.toFixed() : 0}}%</span>
            <div class="
      slice">
      <div class="bar"></div>
      <div class="fill"></div>
    </div>
  </div>


</div>
<hr>
<div class="d-flex justify-content-between align-items-center text-center">
  <div class="d-flex align-items-center justify-content-center">
    <img src="{{icon}}" onError="this.src='./assets/img/wikimaster_logo.png'">
  </div>
  <div>
    <h3 class="blue mb-0 px-2 font-weight-normal">Average Score</h3>
  </div>
  <div class="c100 p{{quiz.quiz_percentage ? quiz.quiz_percentage.toFixed() : 0}} m-0 avg-circle">
    <span>{{quiz.quiz_percentage ? quiz.quiz_percentage.toFixed() : 0}}%</span>
    <div class="slice">
      <div class="bar"></div>
      <div class="fill"></div>
    </div>
  </div>
</div>
<hr>
<div class="d-flex justify-content-between align-items-center text-center">
  <div class="col-4 text-left p-0">
    <h6 class="green font-14 mb-0 px-2 font-weight-normal">Questions Correct</h6>
  </div>
  <div class="col-4 font-14 text-center green bar_no">{{quiz.correct_answers}}</div>
  <div class="col-4 pr-0">
    <div class="bar_bg float-right">
      <div [style.width.%]="correct_answers_percent" class="bar float-right green_bg">&nbsp;</div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center text-center">
  <div class="col-4 text-left p-0">
    <h6 class="grey font-14 mb-0 px-2 font-weight-normal">Incorrect Answer</h6>
  </div>
  <div class="col-4 font-14 text-center grey bar_no">{{quiz.incorrect_answers}}</div>
  <div class="col-4 pr-0">
    <div class="bar_bg float-right">
      <div [style.width.%]="incorrect_answers_percent" class="bar float-right blue_bg">&nbsp;</div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center text-center">
  <div class="col-6 p-0 text-left">
    <h6 class="grey font-14 mb-0 px-2 font-weight-normal">Time Taken</h6>
  </div>

  <div class="col-4 pr-0">
    <div class="grey bar_no">{{quiz.date}}</div>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center text-center">
  <div class="col-6 text-left p-0">
    <h6 class="green font-14 mb-0 px-2 font-weight-normal">WOK position in article</h6>
  </div>
  <div class="col-4 pr-0">
    <div class="green_bg rounded py-1">{{quiz.position_in_tag}}/{{quiz.tag_total_taken}}</div>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center text-center">
  <div class="col-6 text-left p-0">
    <h6 class="green font-14 mb-0 px-2 font-weight-normal">WOKbits earned in quiz</h6>
  </div>
  <div class="col-4 pr-0">
    <div class="green bar_no">{{quiz.wokbits}} Wb</div>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center text-center">
  <div class="col-6 text-left p-0">
    <h6 class="blue font-14 mb-0 px-2 font-weight-normal">Time Finished</h6>
  </div>
  <div class="col-4 pr-0">
    <div class="blue bar_no">{{quiz.total_time}} sec</div>
  </div>
</div>

<div class="d-flex justify-content-between my-3 align-items-center text-center">
  <div class="col-4 pl-0">
    <!-- <img class="w-25" src="./assets/img/ci.png" /> -->
  </div>
  <div class="col-4 p-0  pointer" (click)="openCheckAnswers()">
    <div class="green_bg rounded py-1 d-flex align-items-center clearfix">
      <img src="./assets/img/view.png" class="w-20 float-left ml-3" />
      <span class="ml-4">Review</span>
    </div>
  </div>
  <div class="col-4 pr-0">
    <!-- <img class="w-25" src="./assets/img/share.png" /> -->
  </div>
</div>


</div>