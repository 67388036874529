<div class="container center-abs">
  <div class="row d-flex sticky-top white_bg align-items-center">
    <div class="col-2">
      <img class="w-75 invisible" src="./assets/img/mc/MC_121_color_20.png">
    </div>
    <div class="col-8">
      <nav class="sticky-top py-1 white_bg navbar navbar-expand">
        <div class="collapse navbar-collapse tabs">
          <ul class="navbar-nav mr-auto w-100">
            <li class="nav-item active" [class.active]="viewMode == allSelected" rel="all">
              <a class="nav-link pointer" (click)="onTapSelected(allSelected)">All Answers</a>
            </li>
            <li class="nav-item" [class.active]="viewMode == incorrectSelected || viewMode == correctSelected" rel="incorrect">
              <a class="nav-link pointer" (click)="onTapSelected(allCorrect ? correctSelected
                : allInCorrect ? incorrectSelected
                : viewMode === incorrectSelected ? correctSelected : incorrectSelected)">
                {{allCorrect || viewMode === correctSelected ? 'Correct' : 'Incorrect'}}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="col-2 text-right">
      <h6 class="blue mb-0" *ngIf="selectedQuestions">{{(index+1)}}/{{total}}</h6>
    </div>
  </div>

  <div class="rows-wrapper">
    <div class="my-1 clearfix">
      <ul class="mx-auto justify-content-center list-unstyled row">
        <ng-container *ngIf="selectedQuestions">
          <div class="qs-wrapper clearfix text-center w-100">
            <div class="d-flex justify-content-between align-items-center float-left">
              <img class="" src="{{selectedQuestions[index].icon}}">
            </div>
            <span class="blue qs_text">
              {{selectedQuestions[index].text}}
            </span>
            <div class="ans-wrapper mt-1 row m-0">
              <div [ngClass]="{'invisible': -1 === selectedQuestions[index].user.answerIndex}" class="col-2 text-center woker-ans{{selectedQuestions[index].user.answerIndex}} p-0">
                <img class="rounded-circle woker-pic" src="{{userAvatar}}" onError="this.src='./assets/img/wikimaster_logo.png'">
                <h6 class="green">{{selectedQuestions[index].user.time}}s</h6>
              </div>
              <div class="col-8 text-center p-0">
                <ul class="list-unstyled">
                  <li *ngFor="let answer of selectedQuestions[index].answers" [ngClass]="{'correct-answer': answer.status}">
                    <h5>{{answer.text}}</h5><span>{{answer.percentage}}%</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="tags-wrapper">
              <div class="tags clearfix">
                <!-- <div class="blue_bg float-left">Add tag</div>
                <div class="blue_bg float-left">Remove tag</div> -->
                <div *ngFor="let tag of selectedQuestions[index].tags;">
                  <div class="green_bg float-left" (click)="openWikiTag(tag)">{{tag.name}}</div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </ul>
    </div>
    <div class="mt-3 text-center clearfix">
      <img class="float-left ml-3 pointer" src="./assets/img/left-arrow.png" (click)="previous()">
      <div *ngFor="let question of selectedQuestions;let i = index" class="bullet" [ngClass]="{'active': i==index}"></div>
      <img class="float-right mr-3 pointer" src="./assets/img/right-arrow.png" (click)="next()">
    </div>
  </div>
</div>
