import { Resource, Head } from '../../conn';

export class User implements Resource {
    countryID: number;
    id: number; // uid
    head: Head;
    firstName: string; // fn
    lastName: string; // ln
    email: string; // email
    password: string; // password.
    alias: string; // alias
    token: string; // WOK token
    loginHash: string; // email_hash
    wokbits: number; // wokbits
    avatar: string; // avatar
    level: string; // level {super_admin, editor,user, nw, anonymous}
    lngid: number; // lngid
    answerId: number;
    answerIndex: number;
    time: number;
    status: boolean;
    cp: number;

    public getName(): string {
        return (this.firstName && this.lastName)
            ? `${this.firstName} ${this.lastName}`
            : this.firstName
                ? this.firstName
                : this.lastName
                    ? this.lastName
                    : 'Anonymous';
    }
}
