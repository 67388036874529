import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIManager } from '../../conn';
import { User, WMApi, UserMapper, Utils } from '../../core';
import { AuthenticationService } from '../../auth/authentication.service';
import { Md5 } from 'md5-typescript';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        private loginService: AuthenticationService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(3)]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        const apiManager = new APIManager<User>(WMApi.USER_LOGIN, UserMapper.getInstance());
        const email = this.loginForm.controls['email'].value;
        const password = this.loginForm.controls['password'].value;
        const loginHash = Utils.md5LoginHash(email, password);

        apiManager.setQueryString('hash', loginHash);
        this.loginService.login(apiManager, loginHash).subscribe(() => {
            this.router.navigate([], { fragment: null });
            location.reload();
        });
    }
}
