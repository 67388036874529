<div class="title_wrap text-center w-100 sticky-top">
  <h5 class="py-4 pointer" (click)="goToWiki()">{{tag_name}} Quiz</h5>
</div>
<div class="container">
  <div class="text-center my-2 mb-md-4">
    <img class="woker-img" src="{{(user | async)?.avatar}}" onError="this.src='./assets/img/wikimaster_logo.png'" />
    <h5 class="blue">{{(user | async)?.firstName}} {{(user | async)?.lastName}}</h5>
  </div>
  <div class="m-0 mt-2 mt-md-4 mx-auto info-wb-wrapper d-flex justify-content-around justify-content-md-between">
    <div class="text-center col-4">
      <div class="green_bg white">{{aqa.user_tag_score}}</div>
      <h6 class="green">Total WOKbits</h6>
    </div>
    <div class="text-center col-4">
      <img class="" src="./assets/img/wb.png">
      <h5 class="yellow mt-2">{{aqa.user_score}} Wb</h5>
    </div>
    <div class="text-center col-4 pointer" (click)="goToNoOfQs()">
      <div class="blue_bg white">{{aqa.user_percentage}}%</div>
      <h6 class="blue">Correct Percent</h6>
    </div>
  </div>
  <div class="m-0 mt-2 mt-md-4 mx-auto info-position-wrapper d-flex justify-content-around">
    <div class="text-center">
      <div class="blue_bg white">{{aqa.user_pos_gain}}</div>
      <h6 class="blue"><img class="mr-2 pb-2" src="./assets/img/position-up.png">Position Gain</h6>
    </div>
    <div class="text-center">
      <div class="green_bg white">{{aqa.user_pos}}</div>
      <h6 class="green pt-2">Current Position</h6>
    </div>
  </div>
  <div class="m-0 mt-2 mx-auto btns-wrapper d-flex justify-content-around">
    <div class="eot-btn review green_bg white pointer clearfix text-center" (click)="onReview()">
      <span class="ml-md-3">Review Quiz</span>
    </div>
    <div class="eot-btn requiz blue_bg white pointer clearfix text-center" (click)="onPlay()">
      <span class="ml-md-3">Take Quiz</span>
    </div>
  </div>
</div>
