import { Component, OnInit } from '@angular/core';
import { Quiz, WMApi, QuizMapper, MenuItems } from '../../core';
import { APIManager, WOKConnection, ItemResponse, More, ConfigService } from '../../conn';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../../environments/config';

@Component({
  selector: 'app-review-quiz',
  templateUrl: './review-quiz.component.html',
  styleUrls: ['./review-quiz.component.css'],
})
export class ReviewQuizComponent implements OnInit {
  qzid: number;
  icon: string;
  incorrect_answers_percent: number;
  correct_answers_percent: number;
  total_qs: number;
  private apiManager: APIManager<Quiz>;
  quiz: Quiz;
  loading: boolean;
  menuItem: MenuItems;

  constructor(private service: WOKConnection,
    route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService) {
    this.apiManager = new APIManager<Quiz>(WMApi.QUIZ_REVIEW, QuizMapper.getInstance());
    this.quiz = new Quiz;
    this.qzid = route.snapshot.queryParams['qzid'];
    this.icon = configService.getConfig()[config.TAG_ICON];
  }

  ngOnInit() {
    if (this.qzid) {
      this.fetchMeQuizzes();
    }
  }

  fetchMeQuizzes() {
    this.loading = true;
    this.apiManager.setPathParams(this.qzid);
    this.service.execute(this.apiManager).subscribe((response: ItemResponse<Quiz>) => {
      response.data.name = this.configService.getConfig()[config.QUIZ_NAME];
      this.quiz = response.data;
      this.incorrect_answers_percent = (this.quiz.incorrect_answers / this.quiz.total_questions) * 100;
      this.correct_answers_percent = (this.quiz.correct_answers / this.quiz.total_questions) * 100;
      this.loading = false;
      this.resolveLinks(response.more);
    });
  }

  resolveLinks(more: More) {
    if (more && more.links) {
      this.configService.setOption(config.API_LINK, more.links[0].api);
    }
  }

  openCheckAnswers() {
    this.router.navigate([], {
      fragment: MenuItems[MenuItems.MENU_REVIEW_QUIZ_ANSWERS],
      queryParams: { qzid: this.qzid }
    });
  }
}
