import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { APIManager, ItemResponse, WOKConnection } from '../conn';
import { MenuItems, User, WMApi, Utils } from '../core';
import { UserManager } from './../core/manager/user.manager';
import { AqaMapper } from './../core/mapper/aqa.mapper';
import { Aqa } from './../core/model/aqa';
import { config } from '../../environments/config';

@Component({
  selector: 'app-eot',
  templateUrl: './eot.component.html',
  styleUrls: ['./eot.component.css']
})
export class EotComponent implements OnInit {
  user: Observable<User>;
  subscription: Subscription;
  tag_name: string;
  language: string;
  aqa: Aqa;
  tid: number;
  configService: any;
  qzid: number;
  private apiManager: APIManager<Aqa>;
  result: any;
  loading: boolean;

  constructor(private service: WOKConnection,
    route: ActivatedRoute,
    userManager: UserManager,
    private router: Router,
  ) {
    this.apiManager = new APIManager<Aqa>(WMApi.EOT, AqaMapper.getInstance());
    this.aqa = new Aqa;
    this.qzid = route.snapshot.queryParams['qzid'];
    this.tid = route.snapshot.queryParams['tid'];
    this.tag_name = localStorage.getItem(config.wikiNameParamKey);
    this.language = localStorage.getItem(config.language);
    this.user = userManager.getUser();
  }

  ngOnInit() {
    this.loading = true;
    this.apiManager.setPathParams([this.tid, this.qzid]);
    this.service.execute(this.apiManager).subscribe((response: ItemResponse<Aqa>) => {
      this.aqa = response.data;
      this.loading = false;
    });
  }

  onReview() {
    this.router.navigate([this.language, Utils.wikipediaURIFormat(this.tag_name)], {
      fragment: MenuItems[MenuItems.MENU_REVIEW_QUIZ],
      queryParams: { qzid: this.qzid }
    });
  }

  onPlay() {
    this.router.navigate([this.language, Utils.wikipediaURIFormat(this.tag_name), 'quiz'], {
      queryParams: { tid: this.tid }
    });
  }

  goToWiki() {

  }

  goToNoOfQs() {
    // this.router.navigate([this.language, Utils.wikipediaURIFormat(this.tag_name)]);
    this.router.navigate([this.language, Utils.wikipediaURIFormat(this.tag_name)], {
      fragment: MenuItems[MenuItems.MENU_TOTAL_QS],
      queryParams: { tid: this.tid }
    });
  }
}

