import {
  HttpErrorResponse,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { config } from '../../environments/config';
import {
  APIManager,
  AuthService,
  BaseResponse,
  Head,
  ItemResponse,
  WOKConnection,
  ConfigService,
} from '../conn';
import { User, UserManager, UserMapper, Utils, WMApi } from '../core';

@Injectable()
export class AuthenticationService implements AuthService {
  constructor(
    private service: WOKConnection,
    private configService: ConfigService,
    private userManger: UserManager
  ) {}

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  public isAuthorized(): Observable<boolean> {
    return this.getAccessToken().pipe(map((token: any) => !!token));
  }

  /**
   * Get access token
   * @description Should return access token in Observable from e.g.
   * localStorage
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    return this.userManger.getAccessToken();
  }

  /**
   * Function, checks response of failed request to determine,
   * whether token be refreshed or not.
   * @description Essentialy checks status
   * @param {Response} response
   * @returns {boolean}
   */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return [401, 403].some((v) => response.status === v); // Unauthorized or Forbidden
  }

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  public bypassInterceptor(url: string): boolean {
    const tokenAPIs = [
      WMApi.USER_LOGIN, // WOKer login
      WMApi.USER_SIGNUP, // WOKer normal signup
      WMApi.NW_REGISTER, // NW register
      // Join NW by
      WMApi.USER_JOIN_SIGN_UP,
      WMApi.USER_JOIN_FB,
      WMApi.USER_JOIN_GOOGLE,
      // Facebook SignUp
      WMApi.USER_SIGNUP_FB,
      // Google login
      WMApi.USER_SIGNUP_GOOGLE,
      // Auth APIs request/refresh token
      WMApi.REQUEST_TOKEN,
      // WMApi.REQUEST_TOKEN_FB,
      // WMApi.REQUEST_TOKEN_GOOGLE
    ];
    return (
      tokenAPIs.some((api) => url.endsWith(api.endpoint)) ||
      config.NONE_INTERCEPT_REQ.some((ignore) => url.indexOf(ignore) !== -1)
    );
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<any>}
   */
  public refreshToken(): Observable<BaseResponse> {
    return this.userManger.getUser().pipe(
      switchMap((user: User) => {
        const apiManager = new APIManager(WMApi.REQUEST_TOKEN, null);
        apiManager.setQueryString(config.hashKeyName, user.loginHash);
        return this.service.execute(apiManager);
      }),
      tap((response: BaseResponse) => this.saveAccessData(response.head)),
      catchError((err) => {
        this.userManger.logout();
        throw err;
      })
    );
  }

  /**
   * EXTRA AUTH METHODS
   */

  public login(
    apiManager: APIManager<User>,
    loginHash?: string
  ): Observable<ItemResponse<User>> {
    return this.service.execute(apiManager).pipe(
      tap((response: ItemResponse<User>) => {
        const user = response.data;
        if (user) {
          if (loginHash) {
            user.loginHash = loginHash;
          } else if (
            !user.loginHash &&
            user.password &&
            (user.alias || user.email)
          ) {
            user.loginHash = Utils.md5LoginHash(
              user.email ? user.email : user.alias,
              user.password
            );
          }
          user.token = response.head.token;
          this.userManger.save(user);
        } else {
          this.configService.setAlert(response.head.msg);
          throw response.head.msg;
        }
      })
    );
  }

  /**
   * Make authentication action on background and store user credentials
   * can execute pending requests or retry original one
   */
  public authenticate(): Observable<any> {
    // ln=khaled
    // &lngid=901
    // &continent_id=8
    // &device_token=(firbase token/gcm)
    // &fn=eng
    // &device_type=android
    // &did=e6d03313-23c5-49dc-abf0-0ea8e2cbb9e1
    // &platform=WW
    // &country_id=254
    // &openssl_hash=
    const apiManager = new APIManager(
      WMApi.NW_REGISTER,
      UserMapper.getInstance()
    );
    apiManager.setQueryString('openssl_hash', Utils.getUUID());
    apiManager.setQueryString('did', Utils.getUUID());
    apiManager.setQueryString('device_type', 'android');
    apiManager.setQueryString('platform', 'WW');
    apiManager.setQueryString('lngid', 901);
    apiManager.setQueryString('continent_id', 8);
    apiManager.setQueryString('country_id', 254);
    return this.login(apiManager);
  }

  /**
   * Save user token from reposne head
   *
   * @private
   * @param {Head} head
   */
  private saveAccessData(head: Head) {
    this.userManger.setAccessToken(head.token);
  }

  /**
   * Function, to implement the way of injecting token on header (recommended), url or body.
   */
  public injectToken(
    req: HttpRequest<any>,
    token: string
  ): Observable<HttpRequest<any>> {
    // return of(req.clone({ headers: req.headers.set('token', token) })); // add token on header.

    /**
     * Handle if token exists on url {back from server with next page}
     * Remove and inject local token.
     * Make default if case as token not exists on URL most wide requests.
     */

    // tmp inject language
    if (-1 === req.url.indexOf('token')) {
      if (-1 === req.url.indexOf('lngid')) {
        const lngid = localStorage.getItem('language')
          ? config.language_map[localStorage.getItem('language')]
          : config.language_map[config.language_default];
        return of(
          req.clone({
            params: req.params.set('token', token).set('lngid', lngid),
          })
        ); // add token as param.
      } else {
        return of(req.clone({ params: req.params.set('token', token) })); // add token as param.
      }
    } else {
      /**
       * When next page url param included with url then update token from url.
       */
      const parser = document.createElement('a');
      parser.href = req.url;
      const params = new HttpParams({
        fromString: parser.search.replace('?', ''),
      });
      params.set('token', token);
      const url = `${parser.protocol}//${parser.host}${parser.pathname}`;
      return of(req.clone({ url: url, params: params }));
    }
  }
}
