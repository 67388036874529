import { Resource, Head } from '../../conn';
import { Question } from './question';

export class Quiz implements Resource {
    id: number;
    submissionToken: string;
    name: string;
    questions: Question[];
    wokbits: number;
    qzName: string;
    percent: number;
    chs: number;
    date: string;
    user_percentage: number;
    correct_answers: number;
    incorrect_answers: number;
    total_questions: number;
    total_time: string;
    tid: number;
    position_in_tag: number;
    tag_total_taken: number;
    quiz_percentage: number;
    tag_percentage: number;
}
