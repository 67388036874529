import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WOKConnection } from './http.service';
import { PublicGuard } from './interceptor/guard/public.guard';
import { ProtectedGuard } from './interceptor/guard/protected.guard';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { ConfigService } from './config.service';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        PublicGuard,
        ProtectedGuard,
        AuthInterceptor,
        WOKConnection,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        }, ConfigService
    ]
})
export class ConnectionLayerModule {
}
