import { Resource } from '../../conn';
export class Tag implements Resource {
    id: number;
    name: string;
    favorite: boolean;
    totalQuestion: number;
    totalUser: number;
    icon: string;
    totalAnsweredQsByUserInTag: number;
    lngAbbr: string;
    lngid: number;
}
