<div class="center-abs">
  <div class="row mx-0">
    <div class="w-100 text-center d-flex align-items-center mx-md-3 mx-0 my-1 justify-content-between">
      <div class="position-relative d-flex align-items-center justify-content-center ml-2 ml-md-0">
        <img class="float-left flag" src="{{icon}}" onError="this.src='./assets/img/wikimaster_logo.png'" alt="">
        <span class="qs-number white blue_bg d-none">1.8k</span>
        <span class="qs-percent white blue_bg d-none">20%</span>
      </div>
      <h5 class="w-80 pt-0 pb-0 float-right mb-0 blue m-1">{{tag_name}}</h5>
      <div class="position-relative d-flex align-items-center justify-content-center">
        <!-- <img class="float-right" src="{{(woker | async)?.avatar}}" alt="">
        <span class="qs-number white green_bg">5</span> -->
      </div>
    </div>
    <div class="search_wrapper clearfix w-100 blue_bg">
      <div class="w-10 float-left invisible">
        <img class="" src="./assets/img/white-earth.png">
      </div>
      <div class="form-group float-left clearfix p-2 mb-0 mx-auto w-80">
        <form>
          <input type="text" class="blue text-center search_icon form-control" placeholder="Search for WOKers"
            (keydown.enter)="$event.preventDefault()" />
        </form>
      </div>
      <div class="w-10 float-left d-flex align-items-center justify-content-start h-100">
        <img class="w-80" src="./assets/img/white-earth.png">
      </div>



    </div>
    <div class="wokers-wrapper w-100">
      <div class="ul_wrapper">
        <ul class="list-unstyled clearfix text-center mx-2 mt-0">
          <li *ngFor="let user of users" class="float-left text-center col-4 position-relative mt-2">
            <div class="position-relative woker-wrapper-img">
              <img class="woker blue-border blue_bg" src="{{user.avatar}}"
                onError="this.src='./assets/img/wikimaster_logo.png'" alt="">
              <img class="woker-flag" src="./assets/img/flags/flag_{{user.countryID}}.png"
                onError="this.src='./assets/img/WOK_logo.png'">
              <span class="blue_bg percent white">{{this.Math.round(user.cp)}}%</span>
            </div>
            <span class="blue d-block name">{{user.firstName}} <br> {{user.lastName}}</span>
            <span class="green d-block wb">{{user.wokbits}} Wb </span>
          </li>
        </ul>
        <div *ngIf="loading || apiManager.canLoadMore" class="text-center col-md-8 col-12 my-3 mx-auto">
          <img *ngIf="loading" class="border-0 w-25" src="./assets/img/loading1.gif" />
          <button *ngIf="!loading" class="blue pointer rounded blue_bg white" (click)="loadNext()">Show More</button>
        </div>
      </div>
    </div>
    <!-- <div class="m-3 w-100 text-center d-none">
      <button class="btn w-50 white green_bg mx-auto">Challenge</button>
    </div> -->


  </div>
</div>
