<div class="center-abs">
  <div class="fixed-top-part">
  <div class="d-flex justify-content-md-around justify-content-between pt-2 px-2">
    <div class="tq_circle text-center w-20 pointer" (click)="showTakenQs()">
      <div class="green_bg mx-auto">{{totalQs}}</div>
      <p class="green mb-0 mt-2">Taken Questions</p>
    </div>
    <div class="woker_circle text-center">
      <img class="w-80" src="{{(user | async)?.avatar}}" onError="this.src='./assets/img/wikimaster_logo.png'" />
      <h5 class="green mb-0 mt-2">{{(user | async)?.firstName}} {{(user | async)?.lastName}}</h5>
    </div>
    <div class="cq_circle text-center w-20 pointer" (click)="showCreatedQs()">
      <div class="blue_bg mx-auto">{{createdQs}}</div>
      <p class="blue mb-0 mt-2">Created Questions</p>
    </div>
  </div>
  <div class="search_wrapper blue_bg">
    <form>
      <div class="form-group clearfix p-2 mb-0 mx-auto w-80">
        <input
          type="text"
          class="blue text-center search_icon form-control"
          placeholder="Search for Question"
          (keydown.enter)="$event.preventDefault()"
        />
      </div>
    </form>
  </div>
</div>
  <div class="qs_wrapper">
    <!-- <ul *ngIf="questions" class="p-0 m-0 list-unstyled taken"> -->
        <ul  class="p-0 m-0 list-unstyled taken">
        <li *ngFor="let qs of questions" class="mt-2 qs_wrapper">
          <div class="row mx-3 qs_head">
            <div
              class="col-3 h-100 text-center p-0 d-flex align-items-center justify-content-center"
            >
              <img class="mw-100 mh-100" src="{{qs.img_url}}" onError="this.src='./assets/img/wikimaster_logo.png'" />
            </div>
            <div class="blue col-9 p-0">
              <p class="blue ml-1 my-0">
                {{qs.text}}
              </p>
            </div>
          </div>
          <div class="clearfix mx-3 mt-2">
            <div class="w-20 float-left">
              <img class="float-left" src="./assets/img/graph.png" />
              <h6 class="grey mb-0 ml-1 mt-2">{{qs.tk}}</h6>
            </div>
            <div class="w-20 float-left">
              <img class="float-left" src="./assets/img/correct.png" />
              <h6 class="green mb-0 ml-1 mt-2">{{qs.cp}}%</h6>
            </div>
            <div class="w-20 green text-center float-left">
              <h6 class="green mb-0 ml-1 mt-2">{{qs.class}}</h6>
            </div>
            <div class="w-20 float-left text-center">
                <img [src]="qs.hearted ? './assets/img/heart.png' : './assets/img/unheart.png'" />
            </div>
            <div class="w-20 float-left pointer" (click)= "editQs()" >
              <img class="float-right" src="./assets/img/pen.png" />
            </div>
          </div>
          <div class="hr w-95 mx-auto"></div>
        </li>
      </ul>
      <div *ngIf="loading || takenQsApiManager.canLoadMore" class="text-center col-md-8 col-12 my-3 mx-auto">
          <img *ngIf="loading" class="border-0 w-25" src="./assets/img/loading1.gif" />
          <button *ngIf="!loading" class="blue pointer rounded blue_bg white" (click)="loadNext()">Show More</button>
        </div>
  </div>
</div>
