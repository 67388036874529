import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { APIManager, ListResponse, WOKConnection, ConfigService, More, ListMoreResponse } from '../conn';
import { User, UserManager, WMApi, QuestionMapper, Question } from '../core';
import { config } from '../../environments/config';


export interface EQMore extends More {
  total_questions: number;
  user: { created_questions: number };
}

@Component({
  selector: 'app-eq',
  templateUrl: './eq.component.html',
  styleUrls: ['./eq.component.css']
})
export class EqComponent implements OnInit {

  createdQs: number;
  totalQs: number;
  questionMapper: QuestionMapper;
  response: ListResponse<Question>;
  takenQsApiManager: APIManager<Question>;
  createdQsApiManager: APIManager<Question>;
  user: Observable<User>;
  loading: boolean;
  questions: Question[];
  showTaken: boolean;
  showCreated: boolean;
  private subscription: Subscription;
  constructor(private service: WOKConnection,
    userManager: UserManager,
    private configService: ConfigService) {
    // this.takenQsApiManager = new APIManager<Question>(WMApi.EQ_TAKEN, QuestionMapper.getInstance());
    this.createdQsApiManager = new APIManager<Question>(WMApi.EQ_CREATED, QuestionMapper.getInstance());
    this.user = userManager.getUser();
    this.questionMapper = QuestionMapper.getInstance();
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.listTakenQs(new APIManager<Question>(WMApi.EQ_TAKEN, this.questionMapper));
    // this.listTakenQs();
  }
  showCreatedQs() {
    this.showTaken = false;
    this.showCreated = true;
  }
  showTakenQs() {
    this.showTaken = true;
    this.showCreated = false;
  }
  listTakenQs(takenQsApiManager: APIManager<Question>) {
    this.loading = true;
    this.takenQsApiManager = takenQsApiManager;
    this.subscription.add(this.service.executeMore<Question, EQMore>(takenQsApiManager)
      .subscribe((response: ListMoreResponse<Question, EQMore>) => {
        if (response && response.data) {
          this.questions = this.questions && takenQsApiManager.append
            ? this.questions.concat(response.data)
            : response.data;
          this.totalQs = response.more.total_questions;
          this.createdQs = response.more.user.created_questions;
        }
        this.loading = false;
      }));
  }
  // listTakenQs() {
  //   this.loading = true;
  //   this.service.execute(this.takenQsApiManager).subscribe((response: ListResponse<Question>) => {
  //     this.response = response;
  //     console.log(response);
  //   });
  // }
  listCreatedQs() {
    this.loading = true;
    this.service.execute(this.createdQsApiManager).subscribe((response: ListResponse<Question>) => {
      this.loading = false;
    });
  }
  loadNext() {
    this.listTakenQs(this.takenQsApiManager);
  }
  editQs() {
    this.configService.setAlert(config.EDIT_MSG);
  }
}
