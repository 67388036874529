<div class="title_wrap w-100 fixed-top" id="top-bar">
  <div
    class="h-100 d-flex mx-md-3 p-md-2 justify-content-between justify-content-md-around align-items-center items-wrapper"
  >
    <div class="position-relative item-w">
      <div
        class="text-white text-center nounderline pointer position-relative"
        (click)="openOverlay()"
      >
        <img
          class="w-50"
          src="./assets/img/w.png"
          alt="WikiMaster logo of WOKcraft.Inc"
        />
        <small class="m-0 mt-2 d-none d-md-block">WikiMaster</small>
      </div>
    </div>
    <div class="overlay" [style.width.%]="overLayWidth">
      <a class="closebtn pointer" (click)="closeOverlay()">&times;</a>
      <div class="overlay-content">
        <p class="blue">
          Welcome to
          <span (click)="SiteUtils.goToWikiMasterSite()">WikiMaster</span> .
          <br />
          We have made Wikipedia more useful for students. And for trivia
          lovers. We have created the Knowledge Network WOK combining
          Encyclopedia, Gamification and Social Media. <br /><br />You can use
          this site as Wikipedia. And you can check that you actually know
          things by Take a Quiz in the articles. The Multiple Choice Questions
          (MCQs) are made by the WOK Community, just as the Wikipedia articles
          are made by the Wikipedia Community. Our Community has created over
          462000 MCQs and it grow every day.<br /><br />
          WOK is not an associated affiliate of
          <span (click)="SiteUtils.goToWikipeiaSite()">Wikipedia</span> or
          <span (click)="SiteUtils.goToWikimediaSite()"
            >Wikimedia Foundation</span
          >
          . We are an independent cousin. WOK and WikiMaster is brands from
          <span (click)="SiteUtils.goToWOKcraftSite()">WOKcraft Inc</span> and
          this site is a part if the Knowledge Network WOK. WOK is short for
          World of Knowledge.<br /><br />
          Currently We have WikiMaster app in
          <span (click)="SiteUtils.goToGP()">Google Play</span> and
          <span (click)="SiteUtils.goToAppStore()">AppStore</span>,&nbsp;
          <span (click)="SiteUtils.goToWikiFlipSite()">WikiFlip</span> app in
          <span (click)="SiteUtils.goToWFGP()">Google Play</span> and
          <span (click)="SiteUtils.goToWFAppStore()">AppStore</span> and
          <span (click)="SiteUtils.goToQuizKingSite()">Quiz King</span> app in
          <span (click)="SiteUtils.goToQKGP()">Google Play</span>
          and <span (click)="SiteUtils.goToQKAppStore()">AppStore</span> as part
          of WOK ecosystem. Apart from wok.uno.<br /><br />
          Using service at wok.uno is free of charge. Its a service to all
          Wikipedia lovers. You can use it as anonymous or you can register and
          log in as a registered member; a woker and enjoy more functionality.
          This is also free of charge.<br /><br />
          See
          <span (click)="SiteUtils.goToPrivacyPolicySite()"
            >Privacy Policy</span
          >
          <br />
          <span (click)="SiteUtils.goToTermsOfUseSite()">Terms of Use</span>
          <br /><br />
          Welcome to WOK! Enjoy the learning experience!<br />
          More info at
          <span (click)="SiteUtils.goToWOKcraftSite()">www.wokcraft.com</span>
        </p>
      </div>
    </div>
    <div class="position-relative item-tq">
      <div
        class="text-white text-center nounderline pointer position-relative"
        (click)="onStartQuiz()"
      >
        <img
          class="w-50"
          src="./assets/img/tq.png"
          alt="Take Quiz icon in WikiMaster web"
        />
        <small class="m-0 mt-2 d-none d-md-block">Take Quiz</small>
      </div>
    </div>
    <div *ngIf="tag && tag.totalQuestion" class="position-relative item-qs">
      <div
        class="text-white text-center nounderline pointer"
        (click)="onReviewTag()"
      >
        <img
          class="w-50 flashing"
          *ngIf="flashing"
          src="./assets/img/flash.png"
          alt="Displaying icon for total Qs in WikiMaster web"
        />
        <img
          class="w-50 qs-circle"
          src="./assets/img/questions.png"
          alt="Displaying icon for total Qs in WikiMaster web"
        />
        <span class="qs-wrapper">{{ totalQs }}</span>
        <small class="m-0 mt-2 d-none d-md-block">Questions</small>
      </div>
      <div>
        <app-total-qs *ngIf="openMenu(menuItem.MENU_TOTAL_QS)"></app-total-qs>
      </div>
    </div>
    <div class="position-relative item-lq">
      <div
        class="text-white text-center nounderline pointer"
        (click)="openLQ()"
      >
        <img
          class="w-50"
          src="./assets/img/lq.png"
          alt="List all quizzes icon in WikiMaster web"
        />
        <small class="m-0 mt-2 d-none d-md-block">List Quiz</small>
      </div>
      <app-lq
        *ngIf="
          openMenu([
            menuItem.MENU_LQ_LATEST,
            menuItem.MENU_LQ_POPULAR,
            menuItem.MENU_LQ_MAIN,
            menuItem.MENU_LQ_ME
          ])
        "
      >
      </app-lq>

      <app-rq
        *ngIf="
          openMenu([
            menuItem.MENU_REVIEW_ME_QUIZZES,
            menuItem.MENU_REVIEW_QUIZ,
            menuItem.MENU_REVIEW_QUIZ_ANSWERS
          ])
        "
      >
      </app-rq>
    </div>
    <div class="position-relative item-review">
      <div
        class="text-white text-center nounderline pointer position-relative"
        (click)="toggleMenuItem(menuItem[menuItem.MENU_EQ])"
      >
        <img
          class="w-75"
          src="./assets/img/view.png"
          alt="Review your results in wiki in WikiMaster web"
        />
        <small class="m-0 mt-2 d-none d-md-block" i18n>Review</small>
      </div>
      <div>
        <app-eq *ngIf="openMenu(menuItem.MENU_EQ)"></app-eq>
      </div>
    </div>
    <!-- <div class="position-relative item-w">
      <div
        class="text-white text-center nounderline d-md-block pointer"
        (click)="toggleMenuItem(menuItem[menuItem.MENU_EQ])"
      >
        <img class="w-35 eq_icon" src="./assets/img/eq.png" alt="Edit" />
        <small class="m-0 mt-2 d-none d-md-block">Edit Questions</small>
      </div>
      <div><app-eq *ngIf="openMenu(menuItem.MENU_EQ)"></app-eq></div>
    </div> -->

    <div
      *ngIf="
        (user | async)?.level &&
          !['nw', 'anonymous'].includes((user | async)?.level);
        else login_temp
      "
      class="position-relative item-logout"
    >
      <div
        class="text-white text-center nounderline pointer"
        (click)="openoLgout()"
      >
        <img
          class="rounded-circle woker-pic"
          src="{{ (user | async)?.avatar }}"
          onError="this.src='./assets/img/woker-pic-error.png'"
        />
        <small class="m-0 mt-2 d-none d-md-block pointer">{{
          (user | async)?.firstName
        }}</small>
      </div>
    </div>

    <div class="position-relative item-lang">
      <div
        class="pointer text-white text-center nounderlinepointer position-relative"
        (click)="toggleMenuItem(menuItem[menuItem.MENU_LANG])"
      >
        <img
          class="w-50"
          src="./assets/img/globe.png"
          alt="Languages selector icon in WikiMaster web"
        />
        <small class="m-0 mt-2 d-none d-md-block">Language</small>
      </div>
      <div *ngIf="openMenu(menuItem.MENU_LANG)" class="center-abs lang-popup">
        <ul class="list-group m-0">
          <li
            class="list-group-item d-flex align-items-center border-0 pointer p-0 m-md-3"
            (click)="swipeLanguage('ar')"
          >
            <img class="w-25" src="./assets/img/flags/flags_arabic.png" />
            <h6 class="blue ml-3 p-0">عربي</h6>
          </li>
          <li
            class="list-group-item d-flex align-items-center border-0 pointer p-0 m-md-3"
            (click)="swipeLanguage('en')"
          >
            <img class="w-25" src="./assets/img/flags/flags_english.png" />
            <h6 class="blue ml-3 p-0 mb-0">English</h6>
          </li>
          <li
            class="list-group-item d-flex align-items-center border-0 pointer p-0 m-md-3"
            (click)="swipeLanguage('fr')"
          >
            <img class="w-25" src="./assets/img/flags/flags_french.png" />
            <h6 class="blue ml-3 p-0 mb-0">Français</h6>
          </li>
          <li
            class="list-group-item d-flex align-items-center border-0 pointer p-0 m-md-3"
            (click)="swipeLanguage('sv')"
          >
            <img class="w-25" src="./assets/img/flags/flags_sweden.png" />
            <h6 class="blue ml-3 p-0 mb-0">Svenska</h6>
          </li>
        </ul>
        >
      </div>
    </div>
    <div class="position-relative item-search">
      <div
        class="text-white text-center nounderline pointer"
        (click)="toggleMenuItem(menuItem[menuItem.MENU_SEARCH])"
      >
        <img
          class="w-50"
          src="./assets/img/search.png"
          alt="Searching for Wikipedia in WikiMaster web"
        />
        <small class="m-0 mt-2 d-none d-md-block" i18n>Search</small>
      </div>

      <div *ngIf="openMenu(menuItem.MENU_SEARCH)" class="center-abs-search">
        <div class="form-group clearfix p-2 mb-0 blue_bg">
          <form>
            <form>
              <input
                #search_input
                type="text"
                class="blue form-control"
                (keyup)="searchTerm$.next({ term: $any($event.target).value })"
                [ngClass]="{ 'no-loading': !loading }"
                placeholder="Search Wikipedia"
                i18n-placeholder
                (keydown.enter)="$event.preventDefault()"
              />
            </form>
          </form>
        </div>

        <div *ngIf="pages">
          <div class="search-results">
            <ul class="list-group m-0 list-unstyled">
              <li
                *ngFor="let page of pages; let i = index"
                class="pointer list-group-item border-0 blue justify-content-between"
                (click)="onSearchTermSelected(page.title)"
              >
                <img
                  class="d-none d-md-inline wiki-pic float-left"
                  src="{{ page.thumbnail ? page.thumbnail.source : '' }}"
                  onError="this.src='./assets/img/wikimaster_logo.png'"
                />
                <p class="wiki-title">{{ page.title }}</p>
              </li>
            </ul>
          </div>
          <div
            *ngIf="loading || gpsoffset"
            class="text-center col-md-8 col-12 my-3 mx-auto"
          >
            <img
              *ngIf="loading"
              class="border-0 w-25"
              src="./assets/img/loading1.gif"
            />
            <button
              *ngIf="!loading"
              class="blue pointer rounded blue_bg white"
              (click)="
                searchTerm$.next({
                  term: search_input.value,
                  gpsoffset: gpsoffset
                })
              "
            >
              Show More
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="position-relative item-review">
        <div class="text-white text-center nounderline pointer position-relative"
        (click)="toggleBMenu()">
          <img class="w-75" src="./assets/img/menu.png" alt="Menu in WikiMaster web" />
          <small class="m-0 mt-2 d-none d-md-block">Menu</small>
        </div>
      </div> -->
  </div>
</div>

<div class="header_height_fix"></div>

<div
  class="b_menu blue_bg white text-center"
  [ngClass]="{ 'b-menu-show': hideMenu }"
>
  <div class="w-100 text-center mt-3 mb-4">
    <img
      class="woker_menu"
      src="{{ (user | async)?.avatar }}"
      onError="this.src='./assets/img/wikimaster_logo.png'"
      alt=""
    />
    <h6 class="mt-2">
      {{ (user | async)?.firstName }} {{ (user | async)?.lastName }}
    </h6>
  </div>
  <hr />
  <h4 class="mt-4 pointer" (click)="onSelectLang()">Language</h4>
  <h4 class="mt-4 pointer" (click)="fnNotReady()">Chat</h4>
  <h4 class="mt-4 pointer" (click)="fnNotReady()">Messages</h4>
  <h4 class="mt-4 pointer" (click)="fnNotReady()">Booklets</h4>
</div>
<iframe
  *ngIf="wiki_content; else notFound"
  class="border-0 d-block mx-auto iframe"
  [srcdoc]="wiki_content"
  sandbox="allow-same-origin allow-popups allow-scripts allow-top-navigation"
>
</iframe>

<div class="footer_height_fix"></div>
<div id="footer" class="footer fixed-bottom text-white blue_bg p-1 text-center">
  <app-footer [shortFooter]="true"></app-footer>
</div>
<div class="center"></div>

<!-- Login Template -->
<ng-template #login_temp>
  <div class="position-relative item-login">
    <div
      class="text-white text-center nounderline pointer position-relative"
      (click)="toggleMenuItem(menuItem.MENU_LOGIN)"
    >
      <img
        class="w-50"
        src="./assets/img/login.png"
        alt="Login to your account in WikiMaster web"
      />
      <small class="m-0 mt-2 d-none d-md-block" i18n>Login</small>
    </div>
    <div>
      <app-login
        *ngIf="
          openMenu([
            menuItem.MENU_LOGIN,
            menuItem.MENU_LOGIN_FORM,
            menuItem.MENU_SIGNUP_FORM
          ])
        "
      >
      </app-login>
    </div>
  </div>
</ng-template>

<!-- Logout templat to be displayed on modals. -->
<ng-template #logOut_temp>
  <div class="text-center popup-content">
    <img
      class="rounded-circle woker-pic"
      src="{{ (user | async)?.avatar }}"
      onError="this.src='./assets/img/wikimaster_logo.png'"
    />
    <p class="blue">
      <span class="d-block"
        >You are logged in to WOK as<span class="green">
          {{ (user | async)?.firstName }} {{ (user | async)?.lastName }}</span
        >.</span
      >
      <span class="d-block"
        >Your overall WOKbits is {{ (user | async)?.wokbits }} Wb.
      </span>
      If you like to change your Settings for your WOK account, please download
      the app WikiMaster in
      <span
        class="underline pointer font-weight-bold"
        (click)="SiteUtils.goToGP()"
        >Google Play</span
      >
      or
      <span
        class="underline pointer font-weight-bold"
        (click)="SiteUtils.goToAppStore()"
        >AppStore</span
      >
      . Currently Settings is not availible in the Desktop version.
    </p>
    <div class="d-flex justify-content-around">
      <button
        class="grey_bg white border-0 rounded w-30 p-1 pointer"
        (click)="logout()"
      >
        LogOut
      </button>
      <button
        (click)="hideLogOut()"
        type="button"
        class="border-0 green_bg white rounded w-30 p-1 pointer"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>

<ng-template #notFound>
  <div *ngIf="notFoundError; else loading" class="not-found mx-3">
    <div class="text-center mt-5 mt-md-4">
      <img class="w-50" src="./assets/img/wm-formula.png" />
    </div>
    <h3 class="blue text-center mt-5 mt-md-4">
      This article is not found in Wikipedia yet.
    </h3>
    <h3 class="blue text-center mt-5 mt-md-4">
      You can go to
      <span (click)="SiteUtils.goToWikipeiaSite()" class="pointer underline"
        >Wikipedia</span
      >
      to create it, then you can view it in WikiMaster.
    </h3>
    <h3 class="blue text-center mt-5 mt-md-4">
      Later, you can create questions for this article and enjoy your knowledge.
    </h3>
  </div>
</ng-template>

<ng-template #loading>
  <!-- loading template when waiting for response. -->
  <img class="centered" src="./assets/img/loading1.gif" />
</ng-template>
