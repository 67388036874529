import { Md5 } from 'md5-typescript';
export class Utils {
    // Generate unique IDs for use as pseudo-private/protected names.
    // Similar in concept to
    // <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
    //
    // The goals of this function are twofold:
    // * Provide a way to generate a string guaranteed to be unique when compared
    //   to other strings generated by this function.
    // * Make the string complex enough that it is highly unlikely to be
    //   accidentally duplicated by hand (this is key if you're using `ID`
    //   as a private/protected name on an object)
    public static getUUID(): string {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        let uuid = localStorage.getItem('uuid');
        if (!uuid) {
            uuid = Math.random().toString(36).substr(10, 35);
            localStorage.setItem('uuid', uuid);
        }
        return uuid;
    }

    public static md5LoginHash(email: string, password: string): string {
        return Md5.init(email + Md5.init(password));
    }

    public static shuffleArray<T>(array: T[]): T[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    public static detectmob() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        } else {
            return false;
        }
    }

    public static wikipediaURIFormat(wikiName: string): string {
        return wikiName.split(' ').join('_');
    }
}
