import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  SocialAuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { AuthenticationService } from '../../auth/authentication.service';
import { APIManager } from '../../conn';
import { MenuItems, User, UserMapper, WMApi } from '../../core';

@Component({
  selector: 'app-login-btns',
  templateUrl: './login-btns.component.html',
  styleUrls: ['./login-btns.component.css'],
})
export class LoginBtnsComponent implements OnInit {
  // Store a reference to the enum
  menuItem = MenuItems;

  constructor(
    private router: Router,
    private loginService: AuthenticationService,
    private socialAuthService: SocialAuthService
  ) {}

  ngOnInit() {}

  loginByGoogle() {
    this.loginByProvider(GoogleLoginProvider.PROVIDER_ID);
  }

  loginByFacebook() {
    this.loginByProvider(FacebookLoginProvider.PROVIDER_ID);
  }

  private loginByProvider(provider: string): void {
    const isLoginByFB = provider === FacebookLoginProvider.PROVIDER_ID;
    this.socialAuthService.signIn(provider).then((userData) => {
      const apiManager = new APIManager<User>(
        isLoginByFB ? WMApi.USER_SIGNUP_FB : WMApi.USER_SIGNUP_GOOGLE,
        UserMapper.getInstance()
      );

      apiManager.setQueryString(isLoginByFB ? 'fbid' : 'googleid', userData.id);
      apiManager.setQueryString(
        'ac',
        isLoginByFB ? userData.authToken : userData.idToken
      );
      apiManager.setQueryString('fn', userData.name);
      apiManager.setQueryString('ln', userData.name);
      apiManager.setQueryString('email', userData.email);
      apiManager.setQueryString('os', 'android');
      apiManager.setQueryString('appv', 'v3.37.a2');
      apiManager.setQueryString('platform', 'WW');
      apiManager.setQueryString('lngid', 901);
      apiManager.setQueryString('continent_id', 8);
      apiManager.setQueryString('country_id', 254);
      this.loginService.login(apiManager).subscribe(() => {
        this.router.navigate([], { fragment: null });
        location.reload();
      });
    });
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  onSelect(tap: string) {
    this.router.navigate([], { fragment: tap });
  }
}
