export class SiteUtils {
    public static goToWOKcraftSite(): void {
        window.open('https://wokcraft.com/', '_blank');
    }
    public static goToWikiFlipSite(): void {
      window.open('https://wikiflip.net/', '_blank');
    }
    public static goToQuizKingSite(): void {
    window.open('https://quizking.org/', '_blank');
    }
    public static goToWikipeiaSite(): void {
        window.open('https://www.wikipedia.org/', '_blank');
    }
    public static goToWikimediaSite(): void {
        window.open('https://wikimediafoundation.org/', '_blank');
    }
    public static goToWikiMasterSite(): void {
        window.open('https://wikimaster.com/', '_blank');
    }
    public static goToGP(): void {
        window.open('https://play.google.com/store/apps/details?id=com.wok.wiki', '_blank');
    }
    public static goToAppStore(): void {
        window.open('https://itunes.apple.com/eg/app/wikimaster/id1062887157?mt=8', '_blank');
    }
    public static goToWFGP(): void {
      window.open('https://play.google.com/store/apps/details?id=com.wok.wikiflip', '_blank');
    }
    public static goToWFAppStore(): void {
      window.open('https://itunes.apple.com/eg/app/wikiflip/id1156078290?mt=8', '_blank');
    }
    public static goToQKGP(): void {
      window.open('https://play.google.com/store/apps/details?id=com.wok.quizking', '_blank');
    }
    public static goToQKAppStore(): void {
      window.open('https://itunes.apple.com/sa/app/quiz-king/id887963198?mt=8', '_blank');
    }
    public static goToTermsOfUseSite(): void {
      window.open('https://wokcraft.com/terms-of-service', '_blank');
    }
    public static goToPrivacyPolicySite(): void {
      window.open('https://wokcraft.com/privacy-policy', '_blank');
    }
}
