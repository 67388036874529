export enum MenuItems {
    MENU_LQ_LATEST = 'MENU_LQ_LATEST',
    MENU_LQ_POPULAR = 'MENU_LQ_POPULAR',
    MENU_LQ_MAIN = 'MENU_LQ_MAIN',
    MENU_LQ_ME = 'MENU_LQ_ME',
    MENU_CHAT = 'MENU_CHAT',
    MENU_REVIEW_ME_QUIZZES = 'MENU_REVIEW_ME_QUIZZES',
    MENU_REVIEW_QUIZ = 'MENU_REVIEW_QUIZ',
    MENU_REVIEW_QUIZ_ANSWERS = 'MENU_REVIEW_QUIZ_ANSWERS',
    MENU_CQ = 'MENU_CQ',
    MENU_LOGIN = 'MENU_LOGIN',
    MENU_LOGIN_FORM = 'MENU_LOGIN_FORM',
    MENU_SIGNUP_FORM = 'MENU_SIGNUP_FORM',
    MENU_TOTAL_QS = 'MENU_TOTAL_QS',
    MENU_EQ = 'MENU_EQ',
    MENU_SEARCH = 'MENU_SEARCH',
    MENU_LANG = 'MENU_LANG',
    B_MENU = 'B_MENU',
}
